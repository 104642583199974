import { Col, Row, Typography } from 'antd';
import React from 'react';
import PowerGeneratedStatus from './powerGeneratedStatus';

import PowerGenerationTabs from './powerGenerationTabs';

export default function PowerGeneration() {


    return (
        <>
            <Row justify="start" gutter={[16, 16]}>
                <Typography.Title level={3} type="secondary" className='ml-10'>Power</Typography.Title>
                <PowerGeneratedStatus /> {/* Displaying the powerGeneratedStatus component */}
                <Col span={24}>
                    <PowerGenerationTabs />
                </Col>
            </Row>
        </>
    )
}
