import React from "react";
import { Table, theme, Card } from "antd";
import "../home.css";
import { useSelector } from "react-redux";

function Chargerlist() {
  const generalDetails = useSelector(state => state.sites.generalDetails)

  //transformString function is used to convert the string in the format of {Prefix}_{Suffix} to {Prefix} {Suffix}
  function transformString(input) {
    // Regular expression to match any format "{Prefix}_{Suffix}"
    // Possible inputs: Type_ValueName, Type_Value, value
    const regex = /[^_]+_(.+)/;

    // Use replace method to transform the matched suffix
    const transformedString = input.replace(regex, (_, suffix) => {
      // Replace underscores with spaces
      const replacedUnderscores = suffix.replace(/_/g, ' ');

      // Add space before capital letters
      const withSpacesBeforeCaps = replacedUnderscores.replace(/([A-z0-9])([A-Z])/g, '$1 $2');

      // Check if the transformed string ends with "charger" and modify it to "charger(s)"
      return withSpacesBeforeCaps.replace(/\bCharger\b/g, 'Charger(s)');
    });

    return transformedString;
  }


  const { Truck_Siteid, Truck_ImageUrl, ...filteredGeneralDetails } = generalDetails; //array destructuring, removing the id from the array

  // Transform the generalDetails object into an array of objects with "Setting" and "Value" properties
  const data = Object.entries(filteredGeneralDetails)
    .map(([setting, value]) => ({
      key: setting,  // Use the setting as the key
      Setting: transformString(setting),
      Value: value,
    }
    ));


  const columns = [
    {
      title: 'Setting',
      dataIndex: 'Setting',
      key: 'Setting',
    },
    {
      title: 'Value',
      dataIndex: 'Value',
      key: 'Value',
    },
  ];

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();


  return (
    <Card style={{
      boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)",
      background: colorBgContainer,
      // height: "600px", // Set a fixed height for the Card
      // overflow: "auto",
      // maxHeight: "470px",
      borderRadius: borderRadiusLG, // Apply border radius
    }}>
      <Table
        dataSource={data}
        columns={columns}
        bordered
        pagination={false} // Optional: Disable pagination if you want
        style={{ lineHeight: "1px" }}
      />
    </Card>
  );
}

export default Chargerlist;
