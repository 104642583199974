import { createSlice } from "@reduxjs/toolkit";
import { fetchAllPermittedCustomers, fetchUserInfo, updateShowIntroStatus } from './apis';

const initialState = {
  customerList: null,
  selectedCustomerName: null,
  isLoadingCustomer: false,
  isSuccessCustomer: false,
  errorMessage: '',
  hideIntroPage: false,
  userInfo: [],
  isLoadingUserInfo: false,
  isSuccessUserInfo: false,
}

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setSelectedCustomerName(state, action) {
      state.selectedCustomerName = action.payload;
    },
    // Define other reducers here
  },
  extraReducers: builder => { //used when to call async functions
    builder
      .addCase(fetchUserInfo.pending, state => {
        state.isLoadingUserInfo = true;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.isLoadingUserInfo = false;
        state.isSuccessUserInfo = true;
        state.userInfo = action.payload;
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.isLoadingUserInfo = false;
        state.isSuccessUserInfo = false;
        state.errorMessage = action.payload;
      })
      .addCase(updateShowIntroStatus.pending, state => {
        state.isLoadingUserInfo = true;
      })
      .addCase(updateShowIntroStatus.fulfilled, (state, action) => {
        state.isLoadingUserInfo = false;
        state.isSuccessUserInfo = true;
        state.userInfo = action.payload;
      })
      .addCase(updateShowIntroStatus.rejected, (state, action) => {
        state.isLoadingUserInfo = false;
        state.isSuccessUserInfo = false;
        state.errorMessage = action.payload;
      })
      .addCase(fetchAllPermittedCustomers.pending, state => {
        state.isLoadingCustomer = true;
      })
      .addCase(fetchAllPermittedCustomers.fulfilled, (state, action) => {
        state.isLoadingCustomer = false;
        state.isSuccessCustomer = true;
        state.customerList = action.payload;
        state.selectedCustomerName = state.customerList[0].customer;
      })
      .addCase(fetchAllPermittedCustomers.rejected, (state, action) => {
        state.isLoadingCustomer = false;
        state.isSuccessCustomer = false;
        state.errorMessage = action.payload;
      });
  }
});

export const { setSelectedCustomerName } = customerSlice.actions;

export default customerSlice.reducer;