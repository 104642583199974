import { createSlice } from "@reduxjs/toolkit";
import _ from 'lodash';
import { fetchAllChargersCurrentStatus, fetchAllSessionDataForChargers, fetchChargerList, fetchChargerStatusForErrorTab, fetchSummaryData } from './apis';
import dayjs from "dayjs";

const initialState = {
  sessionData: [],
  isLoadingSession: false,
  isSuccessSession: false,
  errorMessage: '',
  fetchingChargerData: false,
  fetchingChargerDataForErrorTab: false,
  chargerStatusData: [],
  chargerDataError: '',
  chargersId: [],
  chargerCurrentStatus: {},
  dataTobeDis: {},
  summaryDataLoading: false,
  summaryData: [],
  sessionTabIndex: 1,
  requestCount: 0,
  fromDate: dayjs().startOf('day').toISOString(),
  toDate: dayjs().endOf('day').toISOString(),
}

const filterToLastItems = (obj) => {
  const result = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] = obj[key].slice(-1) ? obj[key].slice(-1)[0] : {};
    }
  }

  return result;
};

const chargerSlice = createSlice({
  name: 'charger',
  initialState,
  reducers: {
    setSessionTabIndex(state, action) {
      state.sessionTabIndex = action.payload
    },
    setFromDate(state, action) {
      state.fromDate = action.payload;
    },
    setToDate(state, action) {
      state.toDate = action.payload;
    }
    // Define other reducers here
  },
  extraReducers: builder => { //used when to call async functions
    builder
      .addCase(fetchAllSessionDataForChargers.pending, state => {
        state.sessionData = initialState.sessionData;
        state.isLoadingSession = true;
        state.requestCount += 1
      })
      .addCase(fetchAllSessionDataForChargers.fulfilled, (state, action) => {
        state.isSuccessSession = true;
        state.sessionData = action.payload;
        state.requestCount -= 1;
        if (state.requestCount === 0) {
          state.isLoadingSession = false;
        }

      })
      .addCase(fetchAllSessionDataForChargers.rejected, (state, action) => {
        state.isSuccessSession = false;
        state.sessionData = initialState.sessionData;
        state.errorMessage = action.payload;
        state.requestCount -= 1;
        if (state.requestCount === 0) {
          state.isLoadingSession = false;
        }
      }).addCase(fetchChargerList.fulfilled, (state, action) => {
        state.chargersId = _.compact(_.map(action.payload, "id"));
      }).addCase(fetchAllChargersCurrentStatus.pending, (state) => {
        state.fetchingChargerData = true;
      }).addCase(fetchAllChargersCurrentStatus.rejected, (state, action) => {
        state.fetchingChargerData = false;
        state.chargerCurrentStatus = initialState.chargerCurrentStatus;
        state.errorMessage = action.payload;
      }).addCase(fetchAllChargersCurrentStatus.fulfilled, (state, payload) => {
        state.chargerCurrentStatus = { ...payload.payload }
        state.fetchingChargerData = false;
      }).addCase(fetchChargerStatusForErrorTab.pending, (state) => {
        state.fetchingChargerDataForErrorTab = true;
      }).addCase(fetchChargerStatusForErrorTab.rejected, (state, action) => {
        state.fetchingChargerDataForErrorTab = false;
        state.dataTobeDis = initialState.dataTobeDis;
        state.errorMessage = action.payload;
      }).addCase(fetchChargerStatusForErrorTab.fulfilled, (state, payload) => {
        state.dataTobeDis = payload.payload
        state.fetchingChargerDataForErrorTab = false;
      }).addCase(fetchSummaryData.pending, (state) => {
        state.summaryDataLoading = true;
        state.errorMessage = null; // Clear previous error messages
      })
      .addCase(fetchSummaryData.fulfilled, (state, action) => {
        state.summaryData = action.payload;
        state.summaryDataLoading = false;
      })
      .addCase(fetchSummaryData.rejected, (state, action) => {
        state.summaryDataLoading = false;
        state.summaryData = initialState.summaryData;
        if (action.payload && action.payload.canceled) {
          state.errorMessage = 'Request was canceled';
          state.summaryDataLoading = true;
        } else {
          state.errorMessage = action.payload;
        }
      });
  }
});

export const { setSessionTabIndex, setFromDate, setToDate } = chargerSlice.actions;

export default chargerSlice.reducer;