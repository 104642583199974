import { Col, Collapse, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
//import type { CollapseProps } from 'antd';
import "antd/dist/reset.css";
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPictureListByCamera } from "../../../redux/reducers/apis";
import { ConvertDate } from './CameraTable';
import { CameraSearchToolBar, EbImage, EbImageRow } from './CameraTools';

function uniqueListOfDays(fullList) {
    const justdaysStr = fullList.map(day => new Date(day.createdAt).getDate())
    console.log("justdaysStr", justdaysStr)
    const justdays = justdaysStr.map(day => new Date.parse(day))
    // const justday = justdaysStr.map(day => day.date())
    console.log("justdays", justdays)
    return uniqueDays(justdays)
}

function uniqueDays(dateTimeList) {
    // console.log("dateTimeList",dateTimeList)
    let uniqueDates = new Set();
    dateTimeList.forEach(row => {
        let date = new Date(row.createdAt)
        let dateString = date.toISOString().split('T')[0];
        // console.log("dateString",dateString)
        uniqueDates.add(dateString);
    });
    return Array.from(uniqueDates);
}

function filterRecordsByDate(records, targetDate) {
   var ret = records.filter(row => {
        if (row === null || row.createdAt === null) return 0;
        let recordDate = new Date(row.createdAt).toISOString().split('T')[0];
        // console.log("comapre",[ recordDate,targetDate, recordDate === targetDate ] )
        return recordDate === targetDate;
    });
    return ret
}

function filterRecordsByCamera(records, targetDate) {
    return records.filter(row => {
        if (row === null || row.createdAt === null) return 0;
        let recordDate = new Date(row.createdAt).toISOString().split('T')[0];
        // console.log("comapre",[ recordDate,targetDate, recordDate === targetDate ] )
        return recordDate === targetDate;
    });
}

const CollapsePropsInterface = {
    key: "1",
    label: "hi",
    children: []
}


function genOneCollapseRow1(rid, rlabel, imageList) {

    const me = new Object();
    me.key = rid;
    me.label = rlabel;
    me.children = []


    for (var i = 0; i < imageList.length; i++) {
        var oneRow = imageList[i]
        me.children.push(
            <React.Fragment key={oneRow.id}>
                <EbImage row={oneRow}></EbImage>
            </React.Fragment>
        )

    }
    return me;
}

function generateCollapseList(fullList, uniqueListOfDays) {
    var ret = Array()
    uniqueListOfDays.map((aDay, index) => {
        var todayListOfImages = filterRecordsByDate(fullList, aDay)
        // console.log("todayListOfImages", todayListOfImages)
        var rowMsg = `${aDay} - ${todayListOfImages.length} images`
        var row = generateOneCollapseRowWithImageGrid(index, rowMsg, todayListOfImages)
        ret.push(row)
    })
    return ret
}

function cleanListOfRecords(fullList) {
    var cleanRow = []

    fullList.map((row, index) => {
        var aRow =
            {
             id: row.id,
             createdAt:   ConvertDate(row.createdAt),
             cameraName: row.cameraName,
             truckId: row.truckId,
             pictureType: row.pictureType,
             picturePath: row.picturePath,
             pictureSize: row.pictureSize,
             detectedAs: row.detectedAs
            }
        cleanRow.push(aRow)
    })
    return cleanRow
}


function generateOneCollapseRowWithImageGrid(rid, rlabel, imageList) {

    const me = new Object();
    me.key = rid;
    me.label = rlabel;
    me.children = []
    for (var i = 0; i < imageList.length; i++) {
        var oneImage = imageList[i]
        me.children.push(<EbImage url={oneImage}></EbImage>)
    }
    return me
}


function RowColgenerateRowAndColContainingImages(index, rowMsg, imageData) {
    return (
        <>
            <Row align='strech' wrap={true} scroll={700}>
                {imageData.map((dbrow, i) =>
                    <Col span={4}>
                        <EbImageRow row={dbrow}/>
                    </Col>
                )}
            </Row>
        </>
    )
}
// update camera_camerapictures
// set "cameraName" = 'domecam'
// where "cameraName"  is null;
// SELECT DISTINCT camera_camerapictures."cameraName" FROM camera_camerapictures;
function RowColgenerateListOfRowsAndColForArangeOfDays(fullList, uniqueListOfDays) {
    var ret = Array()
    uniqueListOfDays.map((aDay, index) => {
        var dirtyList = filterRecordsByDate(fullList, aDay)
        var listOfImagesForAGivenDay = cleanListOfRecords(dirtyList)
        // console.log("todayListOfImages", todayListOfImages)
        var imageCount = listOfImagesForAGivenDay.length;
        var rowMsg = `${aDay} - ${imageCount} images`
        var row = RowColgenerateRowAndColContainingImages(index, rowMsg, listOfImagesForAGivenDay)
        ret.push(row)
    })
    return ret
}


function RowColgenerateManyCollapseRows(fullList, uniqueListOfDays) {
    var ret = []
    var imageArray = RowColgenerateListOfRowsAndColForArangeOfDays(fullList, uniqueListOfDays)
    imageArray.map((dayRow, index) => {
        var listOfImagesForAGivenDay = filterRecordsByDate(fullList, uniqueListOfDays[index])
        var imagecount = listOfImagesForAGivenDay.length
        const me = new Object();
        me.key = index;
        me.label = `${uniqueListOfDays[index]} - ${imagecount} images`
        me.children = []
        me.children.push(dayRow)
        ret.push(me)
    })
    return ret;
}


function TableGenerateListOfRowsAndColForArangeOfDays(fullList, uniqueListOfDays) {
    var ret = Array()
    uniqueListOfDays.map((aDay, index) => {
        var listOfImagesForAGivenDay = filterRecordsByDate(fullList, aDay)
        // console.log("todayListOfImages", todayListOfImages)
        var rowMsg = `${aDay} - ${listOfImagesForAGivenDay.length} images`
        var row = RowColgenerateRowAndColContainingImages(index, rowMsg, listOfImagesForAGivenDay)
        ret.push(row)
    })
    return ret
}

function TableGenerateMany(fullList, uniqueListOfDays) {
    var ret = []
    var imageArray = TableGenerateListOfRowsAndColForArangeOfDays(fullList, uniqueListOfDays)
    imageArray.map((dayRow, index) => {
        var listOfImagesForAGivenDay = filterRecordsByDate(fullList, imageArray[index])
        const me = new Object();
        me.key = index;
        me.label = `${uniqueListOfDays[index]} - ${listOfImagesForAGivenDay.length} images`
        me.children = []
        me.children.push(dayRow)
        ret.push(me)
    })
    return ret;
}

const boxStyle = {
    width: '100%',
    height: 120,
    borderRadius: 6,
    border: '1px solid #40a9ff',
};


export var CameraCardFunctions = {
    refreshDataSet: {}
}
let columns = []
let dataSource = []
let colcnt = 20
let rowcnt = 10


function ShowImages(rows, cols) {
    return (
        <>
            <div className="CameraCard">
                <header className="CameraCard-header">
                    <Table
                        showHeader={false}
                        style={{width: "auto"}}
                        columns={cols}
                        dataSource={rows}
                        scroll={{x: true}}
                    >
                    </Table>
                </header>
            </div>
        </>
    );
}

function CameraCard() {
    const [reloadDataFromClick, setCount] = useState(0);

    CameraCardFunctions.refreshDataSet = () => {
        console.log("reloading CameraCard dataset")
        setCount(reloadDataFromClick => reloadDataFromClick + 1)
    }

    // Start the function
    const dispatch = useDispatch();

    // // these are undependable fix it
    const selectedCustomerName = useSelector(state => state.customer.selectedCustomerName);
    const selectedSite = useSelector(state => state.sites.selectedSite);

    useEffect(() => {
        if(!_.isEmpty(selectedSite) && !_.isEmpty(selectedCustomerName)) {
            dispatch(fetchPictureListByCamera({ selectedCustomerName, selectedSite } ));
        }
    }, [reloadDataFromClick, selectedSite, selectedCustomerName]);

    const CameraInfoList = useSelector(state => state.camera.cameraInfoList);
    if (CameraInfoList.length == 0)
        return;
    const uniqueListOfDays = uniqueDays(CameraInfoList)
    // var tableGenList = popuplateTable(CameraInfoList, uniqueListOfDays)
    // var collapseList = generateCollapseList(CameraInfoList, uniqueListOfDays)
    var collapseList = RowColgenerateManyCollapseRows(CameraInfoList, uniqueListOfDays)
    // popuplateTable(CameraInfoList,uniqueListOfDays)

    return (

        <React.Fragment>
             <CameraSearchToolBar/>
             <Collapse accordion items={collapseList} defaultActiveKey={['0']}/>
             {/*{tableGenList}*/}
        </React.Fragment>
    )
        //     <ShowImages rows={dataSource} cols={columns}></ShowImages>
        //

}

// https://www.youtube.com/@CodeWithAamir/search?query=ant
// https://www.geeksforgeeks.org/reactjs-ui-ant-design-collapse-component/

export default CameraCard
