import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Navigate, redirect } from "react-router-dom";
import axios from 'axios';
import { Spin, Row, Col, Typography } from 'antd'

export default function AppContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const [authorizationHandled, setAuthorizationHandled] = useState(false);
  const [loginStatus, setLoginStatus] = useState(null);

  //checking for user already logged in or not, if yes its redirecting to home page 
  //else its redirecting to the amazon cognito login page

  console.log('loginStatus', loginStatus);

  const clearCookie = () => {
    axios
      .post(`${baseUrl}microapi/logout-cleanup/`, {}, {
        withCredentials: true,
      })
      .then((response) => {
        // Handle the response as needed
        console.log("Response:", response.data);
        initiateLogin();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const code = new URLSearchParams(location.search).get('code');
    if (localStorage.getItem('user')) {
      navigate('/home');
    } else if (code && !authorizationHandled) {
      setAuthorizationHandled(true);
      handleAuthorizationCode(code);
    } else {
      clearCookie();
    }
  }, [location.search]);


  const protocol = process.env.REACT_APP_PROTOCOL;
  const url = process.env.REACT_APP_API_URL;
  const baseUrl = protocol + url;
  // const reactDashboardUrl = process.env.REACT_APP_URL;

  const initiateLogin = () => {
    const authUrl = process.env.REACT_APP_AUTH_URL;
    // Redirect the user to the OAuth2 authorization URL
    window.location.href = authUrl;
  };

  const handleAuthorizationCode = (code) => {
    // Create FormData object
    const formData = new FormData();

    // Append the authorization code to the FormData
    formData.append("code", code);

    // Perform the axios request
    axios
      .post(`${baseUrl}oauth/auth0/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
        }, withCredentials: true,
      })
      .then((response) => {
        // Handle the response as needed
        console.log("Response:", response.data);
        // Now that the authentication is successful, switch to sending cookies
        axios.defaults.withCredentials = true;

        localStorage.setItem('user', true);
        navigate("/Home");
      })
      .catch((error) => {
        console.log(error);
        console.log("Response data:", error.response.data); // Log the response data for more details
        if (error.response.status === 401) {
          clearCookie();
        }
        setLoginStatus('error');
        if (error.response.status === 400) {
          navigate("/errorLogin");
        }
        // initiateLogin();
      });
  };


  return (
    <>
      {loginStatus === 'error' && <div>Error!</div>}
      {loginStatus !== 'success' && loginStatus !== 'error' && (
        <Spin size="large">
          <Row justify="center">
            <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingCol">
              <Typography.Title level={2} type="secondary">Loading. Please wait!</Typography.Title>
            </Col>
          </Row>
        </Spin>

      )}
    </>
  );
}
