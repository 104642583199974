import { Card, Col, Row, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GeneralDetailsTable from "./generalDetailsTable";
import { setDataVisible } from '../../../redux/reducers/siteSlice';
import GlMaps from './GlMaps';
import SystemStatus from './systemStatus';
import './general.css';

function GeneralPage() {

  const dispatch = useDispatch();

  const responsiveColForLiveStatus = {
    xxl: 9,
    xl: 9,
    lg: 9,
    md: 24,
    sm: 24,
    xs: 24,
  };

  const responsiveColForGeneralDetails = {
    xxl: 24,
    xl: 24,
    lg: 24,
    md: 24,
    sm: 24,
    xs: 24,
  };

  const generalDetails = useSelector(state => state.sites.generalDetails);
  const isLoadingGeneralDetails = useSelector(state => state.sites.isLoadingGeneralDetails);
  const selectedSite = useSelector((state) => state.sites.selectedSite);
  const avail_sites = useSelector((state) => state.sites.avail_sites);
  const siteLocation = avail_sites.find(
    (site) => site.name === selectedSite,
  )?.location;
  const [selectedSiteLocationUpdated, setSelectedSiteLocationUpdated] = useState("");

  useEffect(() => {
    if (siteLocation?.latitude > 0) {
      setSelectedSiteLocationUpdated(siteLocation?.last_update_at);
    }
  }, [siteLocation]);

  const handleMapClick = () => {
    dispatch(setDataVisible("Maps"));
  };

  return (
    <div className='general'>
      {/* <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
        <iframe
          src={'https://eboost-noc.retool.com/apps/364c9800-2ee3-11ef-a41a-0b4a2b8e845c/MVP-Dev'}
          style={{ border: 'none', width: '100%', height: '100%' }}
          title="Retool App"
        />
      </div> */}
      {isLoadingGeneralDetails === true ? (<Spin size="large" className="centerSpin"></Spin>) : (
        <>
          <Row className="paddingBottomRow" justify="space-between">
            <Col span={24} className="coverObjectFit">
              <Card size="small" className="marginTopCard">
                <Row gutter={[16, 16]} justify="space-between" className="rowHeight">
                  <Col {...{ xxl: 6, xl: 6, lg: 6, md: 24, sm: 24, xs: 24 }}>
                    <SystemStatus />
                  </Col>
                  <Col {...responsiveColForLiveStatus} className="fullWidthCol">
                    <Card
                      title={
                        <Row justify="space-between" align="middle">
                          <Col>
                            <span>Location</span>
                          </Col>
                          {selectedSiteLocationUpdated && <Col>
                            <span style={{ color: '#888' }}>Last updated: {selectedSiteLocationUpdated + ' UTC'}</span>
                          </Col>}
                          {!selectedSiteLocationUpdated && <Col>
                            <span style={{ color: '#888' }}>GPS OFF</span>
                          </Col>}
                        </Row>
                      }
                      hoverable
                      size="small"
                      className="cardStyle"
                      onClick={handleMapClick}
                    >
                      <GlMaps cardHeight="280px" enableControls={false} main={false} />
                    </Card>
                  </Col>
                  <Col {...responsiveColForLiveStatus} className="fullWidthCol">
                    {generalDetails.length !== 0 ? (
                      <Card
                        title="Your e-Boost"
                        size="small"
                        hoverable
                        className="cardStyle"
                      >
                        <img
                          src={generalDetails.Truck_ImageUrl}
                          className="imageStyle"
                        />
                      </Card>
                    ) : (
                      <Card hoverable className="cardStyle">
                        <Typography.Paragraph className="centerText">
                          Image and site data not found for this site.
                        </Typography.Paragraph>
                      </Card>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          {/* <Row  justify="space-between">
            <Col span={24}>
              <Card size="small" >
                <Row justify="space-between">
                  <Col {...{ xxl: 6, xl: 6, lg: 6, md: 24, sm: 24, xs: 24 }}>
                    <StaticCard cardTitle='Hours in use' />
                  </Col>
                  <Col {...{ xxl: 6, xl: 6, lg: 6, md: 24, sm: 24, xs: 24 }}>
                    <StaticCard cardTitle='Charging session' />
                  </Col>
                  <Col {...{ xxl: 6, xl: 6, lg: 6, md: 24, sm: 24, xs: 24 }}>
                    <StaticCard cardTitle='Fuel consumption' />
                  </Col>
                  <Col {...{ xxl: 6, xl: 6, lg: 6, md: 24, sm: 24, xs: 24 }}>
                    <StaticCard cardTitle='Kwh generated' />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row> */}
          <Row justify="start" className="rowGraph">
            <Col {...responsiveColForGeneralDetails}>
              <Row justify="start" className="rowGraph">
                <Col span={24} className='maxHeight'>
                  {/* Rendering general details */}
                  {generalDetails.length !== 0 && <GeneralDetailsTable />}
                </Col>
              </Row>
            </Col>
          </Row>
        </>)}
    </div>
  )
}

export default GeneralPage;
