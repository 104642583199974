import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined
} from '@ant-design/icons';
import React, { useState } from "react";
import Logo from "../../assets/logo/logo.png";
import LogoSmall from "../../assets/logo/logoSmall.png";

import { Breadcrumb, Button, Col, Layout, Menu, Row, Select, Spin, theme, Typography } from 'antd';
import axios from "axios";
import _ from 'lodash';
import { useDispatch } from "react-redux";
import UserLogo from '../../assets/Icons/user.226x256.png';
import { setSelectedCustomerName } from "../../redux/reducers/customerSlice";
import { initiateLogin } from "../../utils/common";


const { Header, Sider, Content } = Layout;


// This is the site not found component for showing the error message when there is no sites atre attaced to the logged in customer
function Sitenotfound({ customers, selectedCustomerName, selectedSite }) {
  console.log("🚀 ~ Sitenotfound ~ customers:", customers)
  const dispatch = useDispatch();

  const customerNames = _.map(customers, "customer")

  const [collapsed, setCollapsed] = useState(false);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const getItem = (label, key, icon, children, onClick) => {
    return {
      label,
      key,
      icon,
      children,
      onClick,
    };
  };


  const handleSelectCustomerChange = (value) => {
    dispatch(setSelectedCustomerName(value))
}

  const handleCustomerChange = (e) => {
    console.log('customerChange', e.key)
    dispatch(setSelectedCustomerName(e.key));
  };

  const userMenuItems = customers?.map(customer => getItem(customer.customer, customer.customer));
  const reactDashboardUrl = process.env.REACT_APP_URL;
  // const signOut = () => {
  //   localStorage.removeItem('user');
  //   const logoutURL =`https://eboostdev.auth.ap-south-1.amazoncognito.com/logout?response_type=code&client_id=377djk1ui7n4u7gi14qon9dga3&redirect_uri=${reactDashboardUrl}&state=MlG9gMyakT-uyV0h1EFrYQ&scope=openid%20profile%20email`
  //   window.location.href = logoutURL;
  // };


  const protocol = process.env.REACT_APP_PROTOCOL;
  const url = process.env.REACT_APP_API_URL;
  const baseUrl = protocol + url;


  const signOut = () => {

    axios
      .post(`${baseUrl}oauth/logout/`, { withCredentials: true })

      .then((data) => {
        console.log("<<< hit logout API! >>>");
        // navigate('/logout')
        localStorage.removeItem('user');
        initiateLogin()
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const logo = collapsed ? LogoSmall : Logo;

  const items = [
    // {
    //   label: 'Customer',
    //   key: '1',
    //   icon: <UserOutlined />,
    //   children: userMenuItems,
    //   onClick: handleCustomerChange
    // },
    {
      label: 'Logout',
      key: '2',
      icon: <LogoutOutlined />,
      children: null,
      onClick: signOut
    }
  ];

   //Set the customer dropdown as not clickable when there is only one customer
   const isDisabled = customerNames.length === 1;

  return (
    <div>
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed} theme="light">
          <div style={{ margin: '5px' }}>
            <img
              src={logo}
              alt="logo"
              style={{ height: '40px', width: 'auto', maxWidth: '100%' }}
            />
          </div>
          <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              <Select 
                value={selectedCustomerName}     
                showSearch
                options={customerNames.map(item =>  ({ label: item, value: item  }))} 
                style={{ width: "100%", margin: 5,marginLeft: 10, marginRight: 10}} 
                suffixIcon={<img src={UserLogo} style={{ height: '18px' }} />}
                onChange={handleSelectCustomerChange}
                disabled={isDisabled}
                className={isDisabled ? 'select-disabled' : ''}
              />
            </div>          
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={['1']}
            selectedKeys={[selectedCustomerName]}
            items={items}
          />
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >

            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
                // width: 64,
                // height: 64,
              }}
            />
            {/* <div className='centerLogo' style={{ margin: '-50px' }}>
                        <img
                            src={Logo}
                            height="40px"
                            alt="logo"
                        />
                    </div> */}
            {/* {window.innerWidth <= 600 ? (<div
              style={{
                color: "#0a4776",
                paddingRight: "10px",
                fontSize: "10px"
              }}
            >
              Ver 2.0
            </div>) : (<div
              style={{
                color: "#0a4776",
                paddingRight: "8px",
                whiteSpace: 'nowrap',
                lineHeight: '1.5',
              }}
            >
              Ver 2.0<br />
              API 2.45
            </div>)} */}
            <div style={{ padding: '16px' }}>
              {customers && (
                <Breadcrumb style={{ margin: '16px 0' }} items={[
                  { title: selectedCustomerName },
                  { title: 'Home' }
                ]} />

            )}
            </div>
          </Header>

          <Content
            className='content'
          >
            <Row justify="center">
              <Col span={24} style={{
                // padding: 24,
                height: '100vh',
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }} >

                {customers && !selectedSite ? (<Typography.Title level={2} type="secondary"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100%", /* Ensures the container takes full height */
                    margin: 0 /* Remove default margin */
                  }}>
                  No sites are configured!
                </Typography.Title>) : (<Spin size="large" style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}></Spin>)}

              </Col></Row>
          </Content>
          {/* <Footer style={{ textAlign: 'center' }}>footer</Footer> */}
        </Layout>
      </Layout>
    </div>
  );
}

export default Sitenotfound;
