import { Card, List } from 'antd';
import React from 'react';
import OnStatusImage from "../../../assets/transparent-green-circle.png";
import RedCircle from '../../../assets/red-circle.png';
import OrangeCircle from '../../../assets/orange-circle.png';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectAllSiteDatas, setBpValue, setChargerDetailsPageOpen, setDataVisible, setFromDate, setFuelPercentage, setToDate } from '../../../redux/reducers/siteSlice';
import { CHARGERS, FUEL, GREEN, ORANGE, POWER_GENERATION, RED, SOLAR } from '../../../utils/constants';
import DISABLED_STATUS_IMAGE from "../../../assets/transparent-grey-circle.png";
import { LogarithmicScale } from 'chart.js';

export default function SystemStatus() {
    const dispatch = useDispatch();

    const liveDataArray = useSelector(state => state.sites.liveDataArray);
    const fuelPercentage = useSelector(state => state.sites.fuelPercentage);
    console.log("fuelPercentage", fuelPercentage)
    const bpValueArray = useSelector(state => state.sites.bpValueArray);
    const selectedCustomerName = useSelector(state => state.customer.selectedCustomerName);

    useEffect(() => {
        // if(selectedCustomerName === "fairfield" || selectedCustomerName === "bc-hydro") {
        //     dispatch(setFuelPercentage(liveDataArray?.find(item => item.value_name === "Fuel Level (%)")?.value))
        // } else {
        dispatch(setFuelPercentage(liveDataArray?.find(item => item.value_name === "Fuel Level" || item.value_name === "Fuel Level (%)")?.value))
        // }
    }, [liveDataArray])

    const ampsAValue = liveDataArray?.find(
        (obj) => obj.value_name === "Amps A"
    )?.value;

    const ampsAStatus = // setting generator status based on the amps A Value
        ampsAValue === -99 || ampsAValue === 0 || ampsAValue === "N/A" || ampsAValue === undefined
            ? "OFF"
            : "ON";
    // If Amps A field is N/A or -99 or 0, that means generator is off
    // If field is GT 0 then generator is on.

    // const dataForGeneral = [
    //     {
    //         name: 'Generators',
    //         value: ampsAStatus === "ON"
    //             ? GREEN
    //             : ORANGE
    //     },
    //     {
    //         name: 'Chargers',
    //         value: GREEN
    //     },
    //     {
    //         name: 'Fuel',
    //         value: fuelPercentage >= 50 ? GREEN : (fuelPercentage < 50 && fuelPercentage >= 25) ? ORANGE : RED
    //     },
    //     {
    //         name: 'Solar battery',
    //         value: GREEN
    //     },
    //     {
    //         name: 'Generator battery',
    //         value: GREEN
    //     },
    // ];


    const siteData = useSelector(selectAllSiteDatas);
    const isLoadingBPLiveData = useSelector(state => state.sites.isLoadingBPLiveData);
    const selectedSite = siteData.selectedSite;
    const avail_sites = siteData.avail_sites;
    const generalItems = avail_sites.find((site) => site.name === selectedSite)?.preferences?.general;
    // Extract system status data
    const systemStatus = generalItems?.systemstatus;

    // Filter items that have their status set to "Show"
    const itemsToShow = systemStatus != undefined ? Object.entries(systemStatus).filter(([key, value]) => value[1] === "Show") : [];

    console.log("itemsToShow", itemsToShow)


    const handleItemClick = (item) => {
        if (item === 'Generator' || item === 'GeneratorBattery') {
            handlePowerGenerationClick(POWER_GENERATION);
        } else if (item === 'Charger') {
            handleChargerClick(CHARGERS);
        } else if (item === 'SolarBattery') {
            handleSolarClick(SOLAR);
        } else {
            handleFuelClick(FUEL);
        }
    }

    const handlePowerGenerationClick = (item) => {
        dispatch(setFromDate());// Resetting the fromdate and todate for getting today's data while changing the tab
        dispatch(setToDate());
        dispatch(setBpValue(bpValueArray[0]));
        dispatch(setDataVisible(item));
    };

    const handleChargerClick = (item) => {
        // setSelectedCharger(item.key);
        dispatch(setChargerDetailsPageOpen('false'));
        // Set dataVisible to "Chargers"
        dispatch(setDataVisible(item));

    };

    const handleSolarClick = (item) => {
        dispatch(setDataVisible(item));
    };


    const handleFuelClick = (item) => {
        dispatch(setFromDate()); // Resetting the fromdate and todate for getting today's data while changing the tab
        dispatch(setToDate());
        dispatch(setBpValue("Fuel Level"));
        dispatch(setDataVisible(item));
    };

    return (
        <Card title="System Status" size="small" hoverable style={{ height: "100%", width: "100%", border: "1px solid rgba(0,0,0,.15)" }}>
            <List
                size="small"
                dataSource={itemsToShow}
                renderItem={([key, value]) => (
                    <List.Item size="small" style={{ marginBottom: '10px' }} onClick={value[2] === 'API' ? () => handleItemClick(key) : undefined}>
                        <div>
                            <img
                                src={value[2] === 'Static' ? DISABLED_STATUS_IMAGE : (fuelPercentage === undefined || fuelPercentage === null) ? DISABLED_STATUS_IMAGE :
                                    key === 'Fuel' ? (fuelPercentage >= 50 ? OnStatusImage :
                                        (fuelPercentage < 50 && fuelPercentage >= 25) ? OrangeCircle : (fuelPercentage < 25 && fuelPercentage >= 0) ? RedCircle : fuelPercentage < 0 ? DISABLED_STATUS_IMAGE : OnStatusImage) : OnStatusImage
                                }
                                height="25px"
                                alt={key}
                            />
                        </div>
                        <div style={{ textAlign: 'left' }}>{value[0]}</div>
                    </List.Item>
                )}
                locale={{ emptyText: "No Status Configured" }}
            />
        </Card>
    );
}
