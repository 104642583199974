import { Card, Col, Row, Typography } from "antd";
import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import NoChargerImg from "../../../assets/No_Charger_Img.jpg";
import OrangeCircle from "../../../assets/orange-circle.png";
import RedCircle from "../../../assets/red-circle.png";
import GreenCircle from "../../../assets/transparent-green-circle.png";
import {
  setChargerDetailsPageOpen,
  setSelectedChargerDetails,
} from "../../../redux/reducers/siteSlice";
import { getBatteryStatus } from '../../../utils/common';
import "../home.css";
import "./chargerList.css";

function ChargerList() {
  const dispatch = useDispatch();
  const chargerData = useSelector((state) => state.sites.chargerData);
  const isLoadingChargerData = useSelector(state => state.sites.isLoadingChargerData);
  const chargersCurrentStatusList = useSelector((state) => state.charger.chargerCurrentStatus);
  const fetchingChargerData = useSelector((state) => state.charger.fetchingChargerData);

  const handleImageError = (event) => {
    event.target.src = NoChargerImg;
  };

  const viewChargerDetails = (charger, status) => {
    let selectedCharger = chargerData.filter(
      (data) => data.id === charger.id,
    );

    dispatch(setSelectedChargerDetails({ ...selectedCharger, currentChargerStatus: status }));

    dispatch(setChargerDetailsPageOpen("true"));
  };

  return (
    <Card
      size="small"
      bordered={true}
      className="heightCard"
      title={"All Available Chargers"}
      loading={isLoadingChargerData}
    >
      <Row gutter={[16, 16]}>
        {chargerData.map((data) => {
          const chargerId = _.get(data, "id", '');
          const chargerStatus = _.get(chargersCurrentStatusList, `${chargerId}`, {});
          const chargerCurrentStatus = _.get(chargerStatus, "connectors[0].status", "Available");
          let batteryStatus = getBatteryStatus(chargerCurrentStatus);
          const staticOrNot = data && data.api_source && data.api_source === 'static';
          staticOrNot ? batteryStatus = 'static' : batteryStatus = batteryStatus
          const isStatusChargingClassName = classNames({
            "grey-img": batteryStatus !== 'charging',
          });

          const isStatusFaultedClassName = classNames({
            "grey-img": batteryStatus !== 'fault',
          });

          const isStatusAvailableClassName = classNames({
            "grey-img": batteryStatus !== 'available',
          });

          return (
            <Col key={data.id} xs={24} sm={24} md={12} lg={8} xl={8}>
              <Card
                hoverable
                style={{ height: "100%", flex: 1 }}
                title={data.charger_title}
                size="small"
                onClick={() => viewChargerDetails(data, batteryStatus)}
                bordered={false}
              >
                <Row>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Row>
                      <Col span={24} className="charger-img">
                        <div className="img-container">
                          <img
                            src={data.charger_model.image_src}
                            alt="Charger Vendor"
                            onError={handleImageError}
                            className="charger-image"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Card
                      loading={fetchingChargerData}
                      title="Running-Status"
                      size="small"
                      className="cardUnselected"
                    >
                      <div className="card-row">
                        <img
                          src={batteryStatus === "available" ? GreenCircle : OrangeCircle}
                          className={isStatusAvailableClassName}
                          alt="Available"
                          width={15}
                          height={15}
                        />
                        <span className="status-name">Available</span>
                      </div>
                      <div className="card-row">
                        <img
                          src={OrangeCircle}
                          className={isStatusChargingClassName}
                          alt="Charging"
                          width={15}
                          height={15}
                        />
                        <span className="status-name">Charging</span>
                      </div>
                      <div className="card-row">
                        <img
                          src={batteryStatus === "fault" ? RedCircle : OrangeCircle}
                          className={isStatusFaultedClassName}
                          alt="Faulted"
                          width={15}
                          height={15}
                        />
                        <span className="status-name">Faulted</span>
                      </div>
                      {data.charger_model.model === "HCS-60" && (
                        <>
                          <Row className="power-status-row">
                            <Col span={3} className="circleStyle"></Col>
                            <Col span={21}>&nbsp;&nbsp;&nbsp;100% Power</Col>
                          </Row>
                          <Row className="power-status-row">
                            <Col span={3} className="circleStyle"></Col>
                            <Col span={21}>&nbsp;&nbsp;&nbsp;50% Power</Col>
                          </Row>
                          <Row className="power-status-row">
                            <Col span={3} className="circleStyle"></Col>
                            <Col span={21}>&nbsp;&nbsp;&nbsp;25% Power</Col>
                          </Row>
                          <Row className="power-status-row">
                            <Col span={3} className="circleStyle"></Col>
                            <Col span={21}>&nbsp;&nbsp;&nbsp;Standby</Col>
                          </Row>
                        </>
                      )}
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Card>
  );
}

export default ChargerList;
