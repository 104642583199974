import { Col, Row, Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux';

export default function Communication() {

    const communicationLink = useSelector(state => state.sites.communicationLink);

    return (
        <Row justify="start" style={{ paddingBottom: "10px" }}>
            <Col span={24}>
                <Typography.Title level={3} type="secondary">Communication</Typography.Title>
                <Row justify="start" className="rowGraph">
                    <Col span={24}>
                        <Row
                            justify="start"
                            className="rowGraph"
                            style={{ height: "calc(90vh - 19rem)" }}
                        >
                            {communicationLink != null ? (
                                <Col
                                    span={24}
                                    style={{ height: "calc(90vh - 14rem)", width: "100%" }}
                                >
                                    <div>
                                        <img
                                            src="https://s3-eboost.s3.ap-south-1.amazonaws.com/rut956-840xAuto.webp"
                                            height="200px"
                                            width="200px"
                                        />
                                    </div>
                                    <div style={{ paddingLeft: "20px" }}>
                                        <p>Status: Online</p>
                                        <p>Signal strength: Good</p>
                                        {communicationLink && <a
                                            href={communicationLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            TELTONIKA PORTAL
                                        </a>}
                                    </div>
                                </Col>
                            ) : (
                                <Typography.Paragraph>
                                    No Teltonika device found!
                                </Typography.Paragraph>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
