import { combineReducers } from 'redux';
import customerReducer from './customerSlice'; // Import your customerSlice reducer
import cameraReducer from './cameraSlice'; // Import your customerSlice reducer
import siteReducer from './siteSlice';
import chargerSlice from './chargerSlice';

const rootReducer = combineReducers({
  customer: customerReducer,
  sites: siteReducer,
  charger: chargerSlice,
  camera: cameraReducer
  // Add other reducers here
});

export default rootReducer;