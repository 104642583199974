import React, {useEffect, useState} from "react";
import {Button, Table, Card, DatePicker, Flex, Popconfirm, Radio, Timeline, StyledCollapsePanel, Row, Col} from "antd";
import {Collapse, Tooltip, CollapseProps, Panel} from 'antd';
//import type { CollapseProps } from 'antd';
import {Image, Space} from 'antd';
import {Carousel} from 'antd';
import {useSelector, useDispatch} from 'react-redux';
import {fetchPictureList, fetchPictureListByCamera} from "../../../redux/reducers/apis";
import {EbImage, CameraSearchToolBar, ImagePager, EbImageList} from './CameraTools';
import "antd/dist/reset.css"

function uniqueDays(dateTimeList) {
    // console.log("dateTimeList",dateTimeList)
    let uniqueDates = new Set();
    dateTimeList.forEach(row => {
        let date = new Date(row.createdAt)
        let dateString = date.toISOString().split('T')[0];
        // console.log("dateString",dateString)
        uniqueDates.add(dateString);
    });
    return Array.from(uniqueDates);
}


function filterRecordsByDate(records, targetDate) {
    return records.filter(row => {
        if (row === null || row.createdAt === null) return 0;
        let recordDate = new Date(row.createdAt).toISOString().split('T')[0];
        // console.log("comapre",[ recordDate,targetDate, recordDate === targetDate ] )
        return recordDate === targetDate;
    });
}

function GenerateTables(rows, cols) {
    return (
        <>
            <Table
                showHeader={true}
                style={{width: "auto"}}
                columns={cols}
                dataSource={rows}
                rowKey={(record) => record.id}
                scroll={{x: true}}
            >
            </Table>
        </>
    );
}

export function ConvertDate(inputDate) {
    // Parse the input date string
    const date = new Date(inputDate);

    // Extract date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    let hours = date.getHours();
    const amPM = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert hours to 12-hour format
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Format the date string
    const formattedDate = `${month}/${day} - ${hours}:${minutes}:${seconds} ${amPM}`;

    return formattedDate;
}

export function popuplateTable(fullList, uniqueListOfDays) {

    var columns = []
    var rows = []
    var tablearray = []

    var maxNumDays = 1
//    var maxNumFilteredDays = 500
    var maxImagesPerRow = 8
    var maxNumRecordsDays = 50
    var pictureIndex = 0

    for (let dayIndex = 0; dayIndex < maxNumDays; dayIndex++) {
        var recFilterDay = filterRecordsByDate(fullList, uniqueListOfDays[dayIndex])
        var recFilterIndex = 0;
        var collapseName = `${uniqueListOfDays[dayIndex]} -- ${recFilterDay.length}`
        for (let fDayIndex = 0; fDayIndex < recFilterDay.length; fDayIndex++) {
            for (let recordsPerRowIndex = 0; recordsPerRowIndex < maxNumRecordsDays; recordsPerRowIndex++) {
                var rowData = {}
                try {

                    var urlArray =[]
                    for(var urlIndex = recFilterIndex; urlIndex < 1;urlIndex++)
                    {
                        try {
                            var thisPath = recFilterDay[urlIndex].picturePath;
                            urlArray.push(thisPath);
                        }
                        catch(e)
                        {
                             console.log(`Error:bad picturePath ${urlIndex} continuing`)
                        }
                    }
                    const cr1 = recFilterDay[recFilterIndex].createdAt;
                    const cr = ConvertDate(cr1)
                    const cam = recFilterDay[recFilterIndex].cameraName;
                    // console.log(cr, cam, url)
                    rowData = {
                        id: ""+pictureIndex++,
                        title: ""+collapseName+recordsPerRowIndex,
//                        key: `${fDayIndex}-${pictureIndex}`,
                        key: 'id',
                        uarray: urlArray,
                        date: cr,
                        camera: cam,
                        height: "100",
                    }
                    rows.push(rowData);
                    recFilterIndex+=maxImagesPerRow
                } catch
                    (err) {
                    console.log(`Error:PopulateTable${err}`)
                }
                rows.push(rowData);
            }

        }

        var pictureIndexDisplay = 0
        var colData = []
        for (let ci = 0; ci < 8; ci++) {
            var col= {
                title: "name",
                key: "id",
                width: "auto",
                // render: (record) => <div>record.title</div>
                render: (record) => {
                    <EbImageList url={record.uarray}/>
                        // <EbImage url={record.uarray[0]} subtitle={record.date} camera={record.camera}/>
                }
            }
            pictureIndexDisplay++
            columns.push(col)
        }

    }
    return GenerateTables(rows, columns)
}

// https://www.reddit.com/r/reactjs/comments/15dcxf4/render_image_in_ant_design_table_cell/
// https://www.knowledgehut.com/blog/web-development/ant-design-table-in-react-js
// https://medium.com/@tsichlasj/render-your-data-with-antd-table-06726ed1d433


// export function popuplateTableold(fullList, uniqueListOfDays) {
//
//     var columns = []
//     var rows = []
//     var tablearray = []
//
//     var maxNumDays = 1
//     var maxNumFilteredDays = 500
//     var  maxNumRecordsDays = 2
//     var pictureIndex=0
//
//     for (let dayIndex = 0; dayIndex < maxNumDays; dayIndex++) {
//         var recFilterDay = filterRecordsByDate(fullList, uniqueListOfDays[dayIndex])
//         var collapseName = `${uniqueListOfDays[dayIndex]} -- ${recFilterDay.length}`
//         for (let fDayIndex = 0; fDayIndex < maxNumFilteredDays; fDayIndex++) {
//             for (let recordsPerRowIndex = 0; recordsPerRowIndex < maxNumRecordsDays; recordsPerRowIndex++) {
//                 var colData = {}
//                 try {
//                     pictureIndex++
//                     const url = recFilterDay[fDayIndex].picturePath;
//                     const cr1 = recFilterDay[fDayIndex].createdAt;
//                     const cr = ConvertDate(cr1)
//                     const cam = recFilterDay[fDayIndex].cameraName;
//                     // console.log(cr, cam, url)
//                     colData = {
//                         title: collapseName,
//                         dataIndex: `col_${pictureIndex}`,
//                         key: fDayIndex,
//                         width: "100px",
//                         render: () =>
//                             <EbImage url={url} subtitle={cr} camera={cam}/>
//                     }
//                 } catch
//                     (err) {
//                     console.log(`Error:PopulateTable${err}`)
//                 }
//                 columns.push(colData);
//             }
//
//         }
//
//         var pictureIndexDisplay=0
//         for (let ri = 0; ri < uniqueListOfDays.length; ri++) {
//             var rowData = []
//             for (let ci = 0; ci < uniqueListOfDays.length; ci++) {
//                 rowData[`col_${pictureIndexDisplay}`] = `R${ri + 1}`
//                 pictureIndexDisplay++
//             }
//             rows.push(rowData)
//         }
//     }
//     return GenerateTables(rows, columns)
// }
