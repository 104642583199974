import React, {useState} from 'react';
import {
    Button,
    DatePicker,
    Form,
    Input,
    Select,
    Switch,
    Modal,
} from 'antd';

export const PopupForm = ({onChange}) => {
    // const { onChange } = props;
    const [visible, setVisible] = useState(false);

    const onCreate = (values) => {
        onChange(values);
        setVisible(false);
    };

    return (
        <span>
          <Button onClick={() => {
              setVisible(true);
          }}> Add </Button>
          <MakeReportPopupForm visible={visible} setVisible={setVisible} onCreate={onCreate}
          />
      </span>
    );
};


export const MakeReportPopupForm = (props) => {
    const {visible, setVisible, onCreate} = props;
    const [form] = Form.useForm();

    const handleCreate = () => {
        form
            .validateFields()
            .then((values) => {
                form.resetFields();
                onCreate(values);
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    return (
        <Modal
            visible={visible}
            title="Create a Report"
            okText="Save"

            onCancel={() => {
                setVisible(false);
            }}
            onOk={handleCreate}
        >
            <Form labelCol={{span: 4,}}
                  wrapperCol={{
                      span: 14,
                  }}
                  layout="horizontal"
                  initialValues={{
                      size: 10,
                  }}

            >
                {/*<Form.Item label="Form Size" name="size">*/}
                {/*    <Radio.Group>*/}
                {/*        <Radio.Button value="small">Small</Radio.Button>*/}
                {/*        <Radio.Button value="default">Default</Radio.Button>*/}
                {/*        <Radio.Button value="large">Large</Radio.Button>*/}
                {/*    </Radio.Group>*/}
                {/*</Form.Item>*/}

               <Form.Item label="Name">
                    <Input/>
                </Form.Item>

                <Form.Item label="Type">
                    <Select>
                        <Select.Option value="demo">Charger Usage</Select.Option>
                        <Select.Option value="demo">Location History</Select.Option>
                        <Select.Option value="demo">Refueling History</Select.Option>
                        <Select.Option value="demo">Carbon Savings</Select.Option>
                        <Select.Option value="demo">Battery Level</Select.Option>
                        <Select.Option value="demo">Communications History</Select.Option>
                        <Select.Option value="demo">Maintenance History</Select.Option>
                        <Select.Option value="demo">Provisioning Information</Select.Option>
                    </Select>
                </Form.Item>


                <Form.Item label="Start Date ">
                    <DatePicker/>
                </Form.Item>
                <Form.Item label="End Date ">
                    <DatePicker/>
                </Form.Item>
            </Form>
        </Modal>
    );
};
