import React from "react";
import {InfoCircleOutlined, ThunderboltOutlined} from "@ant-design/icons";
import {
    Card,
    Collapse,
    Flex,
    Divider,
    Typography,
    Space,
    TimePicker,
    DatePicker,
    Radio,
    Select,
    Button,
    Timeline,
    message,
    Popconfirm
} from "antd";
import {CaretRightOutlined} from '@ant-design/icons';

import type {FlexProps} from "antd/lib";
import Meta from "antd/es/card/Meta";

import OrangeCircle from '../../../assets/orange-circle.png';
import dayjs from 'dayjs';

const alignOptions = ['flex-start', 'center', 'flex-end'];

const errorToColor = {
    "new": ["red"],
    "update": ["yellow"],
    "error": ["red"],
    "status": ["yellow"],
    "resolutionStarted": ["#ABEBC6"],
    "resolutionCompleted": ["#239B56"],
};


const timeLineList1 = [
    {
        id: 0,
        error: 'error',
        when: '05/10/2024 - 1:40am',
        message: 'Low Fuel detected - [20%]',
    },
    {
        id: 1,
        error: 'status',
        when: '',
        message: 'Email Alert sent to mitchell@pioneer-emobility.com',
    },
    {
        id: 2,
        error: 'error',
        when: '05/11/2024 - 10:10am',
        message: 'Very Low Fuel detected - [10%]',
    },
    {
        id: 3,
        error: 'status',
        when: '',
        message: 'Email Alert sent to [mitchell@pioneer-emobility.com]',
    },
    {
        id: 4,
        error: 'resolutionStarted',
        when: '05/12/2024 - 4:22pm',
        message: 'Refueling started',
    },
    {
        id: 5,
        error: 'resolutionStarted',
        when: '05/12/2024 - 4:52pm',
        message: 'Refueling complete - [98%]',
    },
    {
        id: 6,
        error: 'resolutionCompleted',
        when: '05/12/2024 - 4:52pm',
        message: 'Condition cleared --- Refuel complete',
    }
];

const timeLine1 = timeLineList1.map((row) => (
    {
        id: row.id,
        label: row.when,
        color: errorToColor[row.error],
        children: row.message
    }
))
const timeLineList2 = [
    {
        id: 0,
        error: 'error',
        when: '05/10/2024 - 1:40am',
        message: 'Generator Maintenance - Alarm 20 hours remaining',
    },
    {
        id: 1,
        error: 'status',
        when: '',
        message: 'Notification sent by email - support@vinfast.com,support@pioneer-emobility.com'
    },
    {
        id: 2,
        error: 'error',
        when: '05/12/2024 - 4:22pm',
        message: 'Generator Maintenance - Alarm 10 hours remaining',
    },
    {
        id: 3,
        error: 'status',
        when: '',
        message: 'Maintenance date scheduled - 05/13/2024',
    },
    {
        id: 4,
        error: 'resolutionStarted',
        when: '05/13/2024 - 9:25am',
         message: 'Oil Changed, Belts tightened',
    },
    {
        id: 5,
        error: 'resolutionCompleted',
        when: '05/13/2024 - 1:33pm',
        message: 'Service Complete',
    },
];
const timeLine2 = timeLineList2.map((row) => (
    {
        id: row.id,
        label: row.when,
        color: errorToColor[row.error],
        children: row.message
    }
))
const timeLineList3 = [
    {
        id: 0,
        error: 'error',
        when: '05/17/2024 - 1:40am',
        message: 'Emergency Stop Triggered',
    },
     {
        id: 1,
        error: 'status',
        when: '',
        message: 'Notification sent by email - support@vinfast.com,support@pioneer-emobility.com'
    },

];
const timeLine3 = timeLineList3.map((row) => (
    {
        id: row.id,
        label: row.when,
        color: errorToColor[row.error],
        children: row.message
    }
))

const alarmDataList = [
    {
        id: 0,
        label: "Low Fuel event --- 05/10/2024-12:40am --- Status:   Resolved"    ,
        timeline: timeLine1
    },
    {
        id: 1,
        label: "Generator maintenance required in 20 hours remaining --- 05/04/2024 --- Status: Resolved",
        timeline: timeLine2
    },
    {
        id: 2,
        label: "Heliox #4 --- Emergency Shutdown button pressed  --- Status: Pending" ,
        timeline: timeLine3
    }

]

const cancel = (e) => {
    console.log(e);
    message.error('Click on No');
};

const myAlarms = alarmDataList.map((row) => (
    {
        id: row.id,
        label: row.label,
        children:
            <>
                <div className="timeline_container">
                    <Timeline items={row.timeline}></Timeline>
                    <Popconfirm
                        title="Archive the task?"
                        description="Are you sure to Archive this task?"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button>Archive Event</Button>&nbsp;&nbsp;&nbsp;
                    </Popconfirm>
                    <Popconfirm
                        title="Clear the task?"
                        description="Are you sure to clear this task?"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button>Clear Event</Button>&nbsp;&nbsp;&nbsp;
                    </Popconfirm>
                    <Popconfirm
                        title="Request intervention?"
                        description="Should we request someone folloup on this event?"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button>Request intervention</Button>&nbsp;&nbsp;&nbsp;
                    </Popconfirm>
                </div>
            </>
    }
))

function AlarmHistory() {
    return (
        <>
            <Card size="small" style={{minHeight: '117px'}} className="cardUnselected" title="Filter">&nbsp;&nbsp;
                Date Range
                &nbsp;&nbsp; <DatePicker/>&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp; <DatePicker/>&nbsp;&nbsp;&nbsp;&nbsp;
                <Popconfirm
                    title="Add Manual Alert "
                    description="Should we request someone folloup on this event?"
                    okText="Yes"
                    cancelText="No"
                >
                    <Button>Add Manual Alert</Button>&nbsp;&nbsp;&nbsp;
                </Popconfirm>
                &nbsp;&nbsp;&nbsp;&nbsp;<Button>Search</Button>
            </Card>
            <Collapse align accordion items={myAlarms} defaultActiveKey={['0']}/>

        </>
    )
}

export default AlarmHistory;
//
// # https://medium.com/@alef.duarte/using-ant-design-form-inside-a-modal-in-react-stateless-functional-component-634f33357c80
// # https://ant-design.github.io/sunflower/process-components/use-modal#examples
// # https://ant.design/docs/react/recommendation