import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import dayjs from 'dayjs';
import { clearCookie } from "../../utils/common";
import { POLLINGINTERVAL, TIMEINTERVAL, CHARGER_DATA_POLLING_INTERVAL, NEW_CHARGER_POLLING_RATE } from "../../utils/constants";
import dataJson from '../../../src/views/Home/components/staticCard.json';

const protocol = process.env.REACT_APP_PROTOCOL;
const url = process.env.REACT_APP_API_URL;
const baseUrl = protocol + url;

let siteid;
let postData;
// Create an Axios CancelToken source
let cancelTokenSource = null; // Declare cancelTokenSource variable

let pollingInterval;
let isPollingActive = false; // Flag to track polling status

export const fetchUserInfo = createAsyncThunk('customer/fetchUserInfo', async (_, thunkAPI) => {
  try {
    const { data } = await axios.get(`${baseUrl}customer/userinfo/`, { withCredentials: true });
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
})

export const updateShowIntroStatus = createAsyncThunk('customer/updateShowIntroStatus', async (payload, thunkAPI) => {
  try {
    const { data } = await axios.patch(`${baseUrl}customer/userinfo/`, payload, { withCredentials: true });
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
})

export const fetchAllPermittedCustomers = createAsyncThunk('customer/fetchAllPermittedCustomers', async (_, thunkAPI) => {
  try {
    const { data } = await axios.get(`${baseUrl}microapi/permitted_customers/`, { withCredentials: true });
    if (isPollingActive) {
      clearInterval(pollingInterval); // Stop polling if it's active
      isPollingActive = false; // Reset polling flag
    }
    return data;
  } catch (error) {
    if (error.response === undefined) {
      return thunkAPI.rejectWithValue(error.message);
    }
    console.log("error", error.response.status);
    if (error.response.status === 401) {
      clearCookie();
    }
    if (error.response.status === 403) {
      if (!isPollingActive) {
        startPollingFetchAllPermittedCustomers(thunkAPI.dispatch); // Start polling if it's not active
        isPollingActive = true; // Set polling flag
      }
    }

    return thunkAPI.rejectWithValue(error.message);
  }
})

const startPollingFetchAllPermittedCustomers = (dispatch) => { //start poliing fetchAllPermittedCustomers when 403 received
  pollingInterval = setInterval(async () => {
    try {
      const { data } = await axios.get(`${baseUrl}microapi/permitted_customers/`, { withCredentials: true });
      // If successful, stop polling
      clearInterval(pollingInterval);
      // Dispatch success action with data
      dispatch(fetchAllPermittedCustomers.fulfilled(data)); // Dispatch data back to the thunk
    } catch (error) {
      if (error.response.status === 403) {
        // Continue polling
        console.log('Still 403, continue polling...');
      } else {
        // Handle other errors
        console.error('Error during polling:', error);
        clearInterval(pollingInterval);
      }
    }
  }, POLLINGINTERVAL); // Polling interval (5 seconds)
};

// Fetching all customer site
export const fetchAllCustomerSites = createAsyncThunk('sites/fetchAllCustomerSites', async (selectedCustomerName, thunkAPI) => {
  try {
    const { data } = await axios.get(`${baseUrl}crud/get_customersites`, {
      withCredentials: true, headers: {
        'customer': selectedCustomerName, // Include the customer attribute in the headers
      }
    });
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
})

let cancelTokenSource4 = null;

// Fetching the data history to plot the graph
export const fetchAndFilterGraphData = createAsyncThunk(
  'sites/fetchAndFilterGraphData',
  async (payload, thunkAPI) => {
    const { selectedCustomerName, avail_sites, selectedSite, fromDate, toDate, bpValue, selectedGeneratorId } = payload;

    // Cancel previous request if it exists
    if (cancelTokenSource4) {
      cancelTokenSource4.cancel('Request canceled');
    }

    // Create a new cancel token for the current request
    cancelTokenSource4 = axios.CancelToken.source();

    const FindSiteid = (selectedSite) =>
      avail_sites.find((x) => x.name === selectedSite);

    const siteid = FindSiteid(selectedSite); // Declare siteid variable using const

    //---->Setting default from date and to Date as current today's date<---
    var currentDate = new Date();
    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const endDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      23,
      59,
      59,
      999
    );

    const postData = {
      fromrange: fromDate == undefined ? startDate.toISOString() : fromDate,
      torange: toDate == undefined ? endDate.toISOString() : toDate,
      value_name: bpValue === "Fuel Level" ? (selectedCustomerName === "bc-hydro" || selectedCustomerName === "fairfield" ? "Fuel Level (%)" : bpValue) : bpValue,
      source_id: "bluepillar"
    };

    try {
      const { data } = await axios.post(`${baseUrl}trend/graphdata/${siteid.id}/${selectedGeneratorId}/`, postData, {
        withCredentials: true,
        headers: {
          'customer': selectedCustomerName, // Include the customer attribute in the headers
        },
        cancelToken: cancelTokenSource4.token, // Pass the cancel token to the request config  
      });
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
        // Return an empty array to indicate that the request was canceled
        return [];
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
)

// Calling chargers details attached to the selected site for showing it in charger module
export const fetchChargerList = createAsyncThunk('sites/fetchChargerList', async (payload, thunkAPI) => {
  const { selectedCustomerName, avail_sites, selectedSite } = payload;
  const FindSiteid = (selectedSite) =>
    avail_sites.find((x) => x.name === selectedSite);
  siteid = FindSiteid(selectedSite);
  try {
    const { data } = await axios.get(`${baseUrl}charger/list/${siteid.id}/`, {
      withCredentials: true, headers: {
        'customer': selectedCustomerName, // Include the customer attribute in the headers
      }
    });
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
})


export const fetchChargerListDummy = createAsyncThunk('sites/fetchChargerListDummy', async (payload, thunkAPI) => {
  try {
    const { data } = await axios.get(`https://api.edrv.io/v1.1/chargestations?paginate_enabled=false&sort_by=createdAt&sort_order=desc`, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer hLo9zT5idTTkvUK6Z3lE1icvrDlXVotaxM2PF8wY'
      }
    });
    return data && data.result || [];

    // function generateRandomArray(length, min, max) {
    //   const randomArray = [];

    //   for (let i = 0; i < length; i++) {
    //     const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    //     randomArray.push(randomNumber);
    //   }

    //   return randomArray;
    // }

    // // Example usage:
    // const randomArray = generateRandomArray(3, 1, 100);


    // console.log("<<< hit dummy API! >>>", dataJson);
    // return randomArray;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
})

// Fetching all BP values
export const fetchBPValues = createAsyncThunk('sites/fetchBPValues', async (payload, thunkAPI) => {
  const { selectedCustomerName, avail_sites, selectedSite, selectedGeneratorId } = payload;
  const FindSiteid = (selectedSite) =>
    avail_sites.find((x) => x.name === selectedSite);
  siteid = FindSiteid(selectedSite);
  postData = {
    source_id: "bluepillar",
  };
  try {
    const { data } = await axios.post(`${baseUrl}trend/values/${siteid.id}/${selectedGeneratorId}/`, postData, {
      withCredentials: true, headers: {
        'customer': selectedCustomerName, // Include the customer attribute in the headers
      }
    });
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
})

// Fetching all bluepillar live data for showing it in live section in generator statistics

export const fetchBPLiveData = createAsyncThunk('sites/fetchBPLiveData', async (payload, thunkAPI) => {
  const { selectedCustomerName, avail_sites, selectedSite, selectedGeneratorId } = payload;
  const FindSiteid = (selectedSite) =>
    avail_sites.find((x) => x.name === selectedSite);
  siteid = FindSiteid(selectedSite);
  postData = {
    source_id: "bluepillar",
  };
  try {
    // const { data } = await axios.get(`${baseUrl}trend/live_values/${siteid.id}/`, postData, {
    //   withCredentials: true, headers: {
    //     'customer': selectedCustomerName, // Include the customer attribute in the headers
    //   }
    // });
    const { data } = await axios.get(`${baseUrl}trend/live_values/${siteid.id}/${selectedGeneratorId}/`, {
      withCredentials: true, headers: {
        'customer': selectedCustomerName, // Include the customer attribute in the headers
      }
    });
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
})

// Fetching general details of a site while loading and site change
export const fetchGeneralDetails = createAsyncThunk('sites/fetchGeneralDetails', async (payload, thunkAPI) => {
  const { selectedCustomerName, avail_sites, selectedSite } = payload;
  const FindSiteid = (selectedSite) =>
    avail_sites.find((x) => x.name === selectedSite);
  siteid = FindSiteid(selectedSite);
  try {
    const { data } = await axios.get(`${baseUrl}microapi/generaldetails/${siteid.id}/`, {
      withCredentials: true, headers: {
        'customer': selectedCustomerName, // Include the customer attribute in the headers
      }
    });
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
})

export const startDataFetchingInterval = (payload) => (dispatch) => {
  const intervalId = setInterval(async () => {
    payload.selectedGeneratorId && await dispatch(fetchAndFilterGraphData(payload));
    payload.selectedGeneratorId && await dispatch(fetchBPValues(payload));
    payload.selectedGeneratorId && await dispatch(fetchBPLiveData(payload));
    await dispatch(fetchGeneralDetails(payload));
    await dispatch(fetchGeneratorList(payload));
  }, TIMEINTERVAL);

  return () => clearInterval(intervalId);
};

// Fetching all charger session data site
export const fetchAllSessionDataForChargers = createAsyncThunk('sites/fetchAllSessionDataForChargers', async (payload, thunkAPI) => {
  const { fromDate, toDate, chargerStationId, selectedCustomerName } = payload;

  if (cancelTokenSource) {
    cancelTokenSource.cancel('Request canceled');
  }

  // Create a new cancel token for the current request
  cancelTokenSource = axios.CancelToken.source();

  //---->Setting default from date and to Date as current today's date<---

  const fromrange = fromDate == undefined ? '' : fromDate;
  const torange = toDate == undefined ? '' : toDate;
  try {
    const { data } = await axios.get(`${baseUrl}charger/session/${chargerStationId}/?from_date=${fromrange}&to_date=${torange}`, {
      withCredentials: true,
      headers: {
        'customer': selectedCustomerName, // Include the customer attribute in the headers
      },
      cancelToken: cancelTokenSource.token, // Pass the cancel token to the request config  
    });

    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message);
      // Return an empty array to indicate that the request was canceled
      return [];
    }
    return thunkAPI.rejectWithValue(error.message);
  }
})

let cancelTokenSource7 = null;

export const fetchPictureList =
  createAsyncThunk('camera/cameraInfoList', async (payload, thunkAPI) => {
    const { selectedCustomerName } = payload;
    if (cancelTokenSource7) {
      cancelTokenSource7.cancel('Request canceled');
    }

    cancelTokenSource7 = axios.CancelToken.source();

    try {
      // const customer = "vinfast"
      // const myurl = 'http://localhost:8000/'
      const { data } = await axios.get(`${baseUrl}camera/pictures/list/${selectedCustomerName}/`, {
        withCredentials: true, headers: {
          'customer': selectedCustomerName, // Include the customer attribute in the headers
        },
        cancelToken: cancelTokenSource7.token, // Pass the cancel token to the request config  
      });
      // console.log("fetchPictureList",data)
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  })


export const fetchAllChargersCurrentStatus = createAsyncThunk('charger/fetchAllChargersCurrentStatus', async (payload, thunkAPI) => {
  try {
    const { chargersId, selectedCustomerName } = payload;
    const currentStatus = {};
    for (const chargerId of chargersId) {
      const { data } = await axios.get(`${baseUrl}charger/status/${chargerId}/`, {
        withCredentials: true, headers: {
          'customer': selectedCustomerName, // Include the customer attribute in the headers
        }
      });
      currentStatus[chargerId] = data;
    }

    return currentStatus;

  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

let cancelTokenSource3 = null;


export const fetchChargerStatusForErrorTab = createAsyncThunk('charger/fetchChargerStatusForErrorTab', async (payload, thunkAPI) => {
  try {
    const { chargerId, selectedCustomerName, fromDate, toDate } = payload;
    const currentStatus = {};
    if (cancelTokenSource3) {
      cancelTokenSource3.cancel('Request canceled');
    }

    // Create a new cancel token source for the new request
    cancelTokenSource3 = axios.CancelToken.source();
    const { data } = await axios.get(`${baseUrl}charger/status_summary/${chargerId}/?from_date=${fromDate}&to_date=${toDate}`, {
      withCredentials: true, headers: {
        'customer': selectedCustomerName, // Include the customer attribute in the headers
      },
      cancelToken: cancelTokenSource3.token,
    });
    currentStatus[chargerId] = data;
    return currentStatus;

  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message);
      return thunkAPI.rejectWithValue({ canceled: true });
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const startPollingForChargerData = (payload) => (dispatch) => {
  const intervalId = setInterval(async () => {
    try {
      await dispatch(fetchChargerList(payload));
    } catch (error) {
      console.error("Error fetching charger list:", error);
    }
  }, CHARGER_DATA_POLLING_INTERVAL);

  return () => clearInterval(intervalId);
};


export const startPollingForChargerDummyData = () => (dispatch) => {
  const intervalId = setInterval(async () => {
    try {
      await dispatch(fetchChargerListDummy());
    } catch (error) {
      console.error("Error fetching dummy charger list:", error);
    }
  }, NEW_CHARGER_POLLING_RATE);

  return () => clearInterval(intervalId);
};

let cancelTokenSource8 = null;

export const fetchPictureListByCamera =
  createAsyncThunk('camera/cameraInfoList', async (payload, thunkAPI) => {
    const { selectedCustomerName, selectedSite } = payload;
    if (cancelTokenSource8) {
      cancelTokenSource8.cancel('Request canceled');
    }

    cancelTokenSource8 = axios.CancelToken.source();
    try {
      // console.log("payload=",payload)
      // const myurl = 'http://localhost:8000/'
      const { data } = await axios.get(`${baseUrl}camera/pictures/listForDisplay/${selectedCustomerName}/${selectedSite}/`, {
        withCredentials: true, headers: {
          'customer': selectedCustomerName, // Include the customer attribute in the headers
        },
        cancelToken: cancelTokenSource8.token, // Pass the cancel token to the request config  
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  })

// Fetching general details of a site while loading and site change
let cancelTokenSource5 = null;

export const fetchGeneratorList = createAsyncThunk('sites/fetchGeneratorList', async (payload, thunkAPI) => {
  const { selectedCustomerName, avail_sites, selectedSite } = payload;
  if (cancelTokenSource5) {
    cancelTokenSource5.cancel('Request canceled');
  }
  cancelTokenSource5 = axios.CancelToken.source();
  const FindSiteid = (selectedSite) =>
    avail_sites.find((x) => x.name === selectedSite);
  siteid = FindSiteid(selectedSite);
  try {
    const { data } = await axios.get(`${baseUrl}crud/site_equipments/${siteid.id}/`, {
      withCredentials: true, headers: {
        'customer': selectedCustomerName, // Include the customer attribute in the headers
      },
      cancelToken: cancelTokenSource5.token,
    });
    return data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message);
      return thunkAPI.rejectWithValue({ canceled: true });
    }
    else return thunkAPI.rejectWithValue(error.message);
  }
})
let cancelTokenSource2 = null;

export const fetchSummaryData = createAsyncThunk(
  'trend/summary',
  async (payload, thunkAPI) => {
    const { selectedCustomerName, avail_sites, selectedSite, fromDate, toDate } = payload;
    // Cancel the previous request, if any
    if (cancelTokenSource2) {
      cancelTokenSource2.cancel('Request canceled');
    }

    // Create a new cancel token source for the new request
    cancelTokenSource2 = axios.CancelToken.source();

    const FindSiteid = (selectedSite) =>
      avail_sites.find((x) => x.name === selectedSite);
    const siteid = FindSiteid(selectedSite);

    try {
      const { data } = await axios.get(
        `${baseUrl}trend/chargersummary/${siteid?.id}/?from_date=${fromDate}&to_date=${toDate}`,
        {
          withCredentials: true,
          headers: { 'customer': selectedCustomerName },
          cancelToken: cancelTokenSource2.token,
        }
      );
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
        return thunkAPI.rejectWithValue({ canceled: true });
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
