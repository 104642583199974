import { Col, Row, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

export default function StarLink() {
    const starLink = useSelector(state => state.sites.starLink);

    return (
        <React.Fragment>
            <Row justify="start" className='pb-10'>
                <Col span={24}>
                    <Typography.Title level={3} type="secondary">Starlink Communication</Typography.Title>
                    <Row justify="start" style={{ height: '100vh', width: '100%', overflow: 'hidden' }}>
                        {starLink ? (
                            <div className='w-full h-full'>
                                <iframe
                                    title="starlink-iframe"
                                    style={{
                                        width: "100%",
                                        height: "105vh",  // Set to viewport height
                                        border: "none",
                                        overflow: "hidden" // Prevent scrolling inside iframe
                                    }}
                                    src={starLink}
                                ></iframe>
                            </div>
                        ) : (
                            <Typography.Paragraph>Starlink Not Linked!</Typography.Paragraph>
                        )}
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
}
