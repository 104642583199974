import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAllCustomerSites,
  fetchAndFilterGraphData,
  fetchChargerList,
  fetchBPValues,
  fetchBPLiveData,
  fetchGeneralDetails,
  fetchGeneratorList,
  fetchChargerListDummy
} from './apis';

const initialState = {
  avail_sites: null,
  selectedSite: null,
  victronSite: '',
  starLink: '',
  selectedGeneratorId: '',
  communicationLink: '',
  fromDate: null,
  toDate: null,
  bpSitehistory: [],
  bpValue: "",
  bpValueArray: [],
  liveDataArray: [],
  chargerData: [],
  chargerDataDummy: [],
  generalDetails: [],
  dateRange: [],
  fuelPercentage: null,
  errorMessage: '',
  chargerDetailsPageOpen: 'false',
  selectedChargerDetails: null,
  dataVisible: 'General',
  isLoadingCustomerSites: false,
  isLoadingGeneralDetails: false,
  isLoadingBPValues: false,
  isLoadingBPLiveData: false,
  isLoadingChargerData: false,
  isLoadingChargerDataDummy: false,
  isLoadingGraphData: false,
  historyArray: [],
  generatorList: [],
  isLoadingGeneratorList: false,
  requestCount: 0,
}

const siteSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    setSelectedSiteName(state, action) {
      state.selectedSite = action.payload;
      state.fromDate = initialState.fromDate;
      state.toDate = initialState.toDate;
      state.bpValue = initialState.bpValue;
      state.dateRange = initialState.dateRange;
      state.fuelPercentage = initialState.fuelPercentage;
      state.generalDetails = initialState.generalDetails;
    },
    setVictronSite(state, action) {
      state.victronSite = action.payload;
    },
    setStarLink(state, action) {
      state.starLink = action.payload;
    },
    setSelectedGeneratorId(state, action) {
      state.selectedGeneratorId = action.payload;
    },
    setCommunicationLink(state, action) {
      state.communicationLink = action.payload;
    },
    setFromDate(state, action) {
      state.fromDate = action.payload;
    },
    setToDate(state, action) {
      state.toDate = action.payload;
    },
    setBpValue(state, action) {
      state.bpValue = action.payload;
    },
    setDateRange(state, action) {
      state.dateRange = action.payload;
    },
    setFuelPercentage(state, action) {
      state.fuelPercentage = action.payload;
    },
    setChargerDetailsPageOpen(state, action) {
      state.chargerDetailsPageOpen = action.payload;
    },
    setSelectedChargerDetails(state, action) {
      state.selectedChargerDetails = action.payload;
    },
    setDataVisible(state, action) {
      state.dataVisible = action.payload;
    },
    addToHistoryArray(state, action) {
      state.historyArray.push(action.payload);
    },
    removeFromHistoryArray(state) {
      state.historyArray.pop();
    },
    clearHistoryArray(state) {
      state.historyArray = [];
    }
    // Define other reducers here
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllCustomerSites.pending, state => {
        state.isLoadingCustomerSites = true;
      })
      .addCase(fetchAllCustomerSites.fulfilled, (state, action) => {
        state.isLoadingCustomerSites = false;
        state.avail_sites = action.payload;
      })
      .addCase(fetchAllCustomerSites.rejected, (state, action) => {
        state.isLoadingCustomerSites = false;
        state.errorMessage = action.payload;
      })
      .addCase(fetchAndFilterGraphData.pending, state => {
        state.isLoadingGraphData = true;
        state.requestCount += 1
      })
      .addCase(fetchAndFilterGraphData.fulfilled, (state, action) => {
        state.bpSitehistory = action.payload;
        state.requestCount -= 1;
        if (state.requestCount === 0) {
          state.isLoadingGraphData = false;
        }
      })
      .addCase(fetchAndFilterGraphData.rejected, (state, action) => {
        state.errorMessage = action.payload;
        state.requestCount -= 1;
        if (state.requestCount === 0) {
          state.isLoadingGraphData = false;
        }
      })
      .addCase(fetchChargerList.pending, state => {
        // state.chargerData = initialState.chargerData;
        state.isLoadingChargerData = true;
      })
      .addCase(fetchChargerList.fulfilled, (state, action) => {
        state.isLoadingChargerData = false;
        state.chargerData = action.payload;
      })
      .addCase(fetchChargerList.rejected, (state, action) => {
        state.isLoadingChargerData = false;
        state.errorMessage = action.payload;
      })
      .addCase(fetchChargerListDummy.pending, state => {
        state.isLoadingChargerDataDummy = true;
      })
      .addCase(fetchChargerListDummy.fulfilled, (state, action) => {
        state.isLoadingChargerDataDummy = false;
        state.chargerDataDummy = action.payload;
      })
      .addCase(fetchChargerListDummy.rejected, (state, action) => {
        state.isLoadingChargerDataDummy = false;
        state.errorMessage = action.payload;
      })
      .addCase(fetchBPValues.pending, state => {
        state.isLoadingBPValues = true;
      })
      .addCase(fetchBPValues.fulfilled, (state, action) => {
        state.isLoadingBPValues = false;
        state.bpValueArray = action.payload;
      })
      .addCase(fetchBPValues.rejected, (state, action) => {
        state.isLoadingBPValues = false;
        state.errorMessage = action.payload;
      })
      .addCase(fetchBPLiveData.pending, state => {
        state.isLoadingBPLiveData = true;
      })
      .addCase(fetchBPLiveData.fulfilled, (state, action) => {
        state.isLoadingBPLiveData = false;
        state.liveDataArray = action.payload;
        state.fuelPercentage = state.liveDataArray?.find(item => item.value_name === "Fuel Level")?.value;
      })
      .addCase(fetchBPLiveData.rejected, (state, action) => {
        state.isLoadingBPLiveData = false;
        state.errorMessage = action.payload;
      })
      .addCase(fetchGeneralDetails.pending, state => {
        state.isLoadingGeneralDetails = true;
      })
      .addCase(fetchGeneralDetails.fulfilled, (state, action) => {
        state.isLoadingGeneralDetails = false;
        state.generalDetails = action.payload;
      })
      .addCase(fetchGeneralDetails.rejected, (state, action) => {
        state.isLoadingGeneralDetails = false;
        state.generalDetails = initialState.generalDetails;
        state.errorMessage = action.payload;
      })
      .addCase(fetchGeneratorList.fulfilled, (state, action) => {
        state.isLoadingGeneratorList = false;
        state.generatorList = action.payload;
      })
      .addCase(fetchGeneratorList.rejected, (state, action) => {
        state.isLoadingGeneratorList = false;
        if (action.payload && action.payload.canceled) {
          state.errorMessage = 'Request was canceled';
          state.isLoadingGeneratorList = true;
        } else {
          state.errorMessage = action.payload;
        }
      })
      .addCase(fetchGeneratorList.pending, state => {
        state.isLoadingGeneratorList = true;
        state.generatorList = initialState.generatorList;
      });
  }
});

export const {
  setSelectedSiteName,
  setVictronSite,
  setSelectedGeneratorId,
  setCommunicationLink,
  setFromDate,
  setToDate,
  setStarLink,
  setBpValue,
  setDateRange,
  setFuelPercentage,
  setChargerDetailsPageOpen,
  setSelectedChargerDetails,
  setDataVisible,
  addToHistoryArray,
  removeFromHistoryArray,
  clearHistoryArray
} = siteSlice.actions;

export const selectAllSiteDatas = (state) => state.sites

export default siteSlice.reducer;