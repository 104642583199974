import { Button, Card, Col, DatePicker, Row, Tabs, Typography } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoChargerImg from "../../../assets/No_Charger_Img.jpg";
import OrangeCircle from "../../../assets/orange-circle.png";
import RedCircle from "../../../assets/red-circle.png";
import GreenCircle from "../../../assets/transparent-green-circle.png";
import { fetchAllSessionDataForChargers } from "../../../redux/reducers/apis";
import { setSessionTabIndex } from "../../../redux/reducers/chargerSlice";
import {
  setChargerDetailsPageOpen,
  setSelectedChargerDetails,
} from "../../../redux/reducers/siteSlice";
import { getBatteryStatus } from "../../../utils/common";
import StaticCard from "../components/staticCardWithGraph";
import "./chargerSection.css";
import ErrorJsonDataDisplay from "./chargerSession/errorSession";
import PastSessionJsonDataDisplay from "./chargerSession/pastSession";
import TodaySessionJsonDataDisplay from "./chargerSession/todaySession";

const { TabPane } = Tabs;

const ChargerSection = () => {
  const dispatch = useDispatch();

  const selectedChargerDetails = useSelector((state) => state.sites.selectedChargerDetails);

  const chargerCurrentStatus = selectedChargerDetails && selectedChargerDetails[0] &&
    selectedChargerDetails[0].api_source && selectedChargerDetails[0].api_source === 'static' ? 'static' : selectedChargerDetails?.currentChargerStatus;
  const chargersCurrentStatusList = useSelector((state) => state.charger.chargerCurrentStatus);
  const fetchingChargerData = useSelector((state) => state.charger.fetchingChargerData);
  const chargerData = useSelector(state => state.sites.chargerData);
  const chargersId = useSelector(state => state.charger.chargersId);
  const sessionTabIndex = useSelector(state => state.charger.sessionTabIndex);
  const sessionDataLoading = useSelector(state => state.charger.isLoadingSession);
  const fromDate = dayjs().startOf("day").toISOString();
  const toDate = dayjs().endOf("day").toISOString();
  const [pastSessionStart, setPastSessonStart] = useState(dayjs().subtract(7, 'day').startOf('day'));
  const [pastSessionEnd, setPastSessionEnd] = useState(dayjs().subtract(1, 'day').endOf('day'));
  const chargerStationId = selectedChargerDetails[0]?.id;

  const chargerId = _.get(selectedChargerDetails, "0.id", "");
  const selectedCustomerName = useSelector(state => state.customer.selectedCustomerName);


  useEffect(() => {
    if (sessionTabIndex === 1) {
      dispatch(fetchAllSessionDataForChargers({ fromDate, toDate, chargerStationId, selectedCustomerName }));
    }
    if (sessionTabIndex === 2) {
      dispatch(fetchAllSessionDataForChargers({ fromDate: pastSessionStart?.toISOString(), toDate: pastSessionEnd?.toISOString(), chargerStationId, selectedCustomerName }));
    }
  }, [selectedChargerDetails && selectedChargerDetails[0] && selectedChargerDetails[0]?.id, chargerId]);

  useEffect(() => {
    if (_.isEmpty(chargerCurrentStatus)) {
      if (!_.has(selectedChargerDetails, "currentChargerStatus")) {
        const currentChargerStatus = _.get(chargersCurrentStatusList, chargerId, {});
        const selectedChargerStatus = _.get(currentChargerStatus, "connectors[0].status", "Available",);
        const chargerStatus = getBatteryStatus(selectedChargerStatus);
        dispatch(
          setSelectedChargerDetails({
            ...selectedChargerDetails,
            currentChargerStatus: chargerStatus,
          }),
        );
      }
    }
  }, [selectedChargerDetails]);

  const { RangePicker } = DatePicker;


  useEffect(() => {
    if (chargerId && !_.isEmpty(chargerCurrentStatus)) {

      const currentChargerStatus = _.get(chargersCurrentStatusList, chargerId, {});
      const selectedChargerStatus = _.get(currentChargerStatus, "connectors[0].status", "Available",);
      const chargerStatus = getBatteryStatus(selectedChargerStatus);
      dispatch(
        setSelectedChargerDetails({
          ...selectedChargerDetails,
          currentChargerStatus: chargerStatus,
        }),
      );
    }
  }, [chargersCurrentStatusList])

  const hideChargerDetails = () => {
    dispatch(setChargerDetailsPageOpen("false"));
  };

  const handleImageError = (event) => {
    event.target.src = NoChargerImg;
  };

  const isStatusChargingClassName = classNames({
    "grey-img": chargerCurrentStatus !== "charging",
  });

  const isStatusFaultedClassName = classNames({
    "grey-img": chargerCurrentStatus !== "fault",
  });

  const isStatusAvailableClassName = classNames({
    "grey-img": chargerCurrentStatus !== "available",
  });

  const handleSessionChanges = (key) => {
    if (key == 1) {
      dispatch(setSessionTabIndex(1))
      dispatch(fetchAllSessionDataForChargers({ fromDate, toDate, chargerStationId, selectedCustomerName }));
    }
    if (key == 2) {
      dispatch(setSessionTabIndex(2))
      dispatch(fetchAllSessionDataForChargers({ fromDate: pastSessionStart?.toISOString(), toDate: pastSessionEnd?.toISOString(), chargerStationId, selectedCustomerName }));
    }
    if (key === 3) {
      dispatch(setSessionTabIndex(3));
    }
  };

  const addFilter = (value) => {
    setPastSessonStart(value && value[0] ? value[0] : undefined);
    setPastSessionEnd(value && value[1] ? value[1] : undefined);
  }

  const items = [
    {
      key: "1",
      label: "Today's sessions",
      children: <TodaySessionJsonDataDisplay handleSessionChanges={handleSessionChanges} />,
    },
    {
      key: "2",
      label: "Past sessions",
      children: <div>
        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', gap: '10px' }}>
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            value={[pastSessionStart, pastSessionEnd]}
            maxDate={dayjs().endOf('day').subtract(1, 'day')}
            format="YYYY-MM-DD HH:mm"
            onChange={(value, dateString) => {
              addFilter(value)
            }}
          />
          <Button loading={sessionDataLoading} type="primary" onClick={() => dispatch(fetchAllSessionDataForChargers({ fromDate: pastSessionStart?.toISOString(), toDate: pastSessionEnd?.toISOString(), chargerStationId, selectedCustomerName }))}>Search</Button>
        </div>
        <PastSessionJsonDataDisplay handleSessionChanges={handleSessionChanges} />
      </div>,
    },
    {
      key: "3",
      label: "Error",
      children: <ErrorJsonDataDisplay handleSessionChanges={handleSessionChanges} />,
    },
  ];

  return (
    <div style={{ minHeight: '92vh' }}>
      <div className="pt-20 pb-20">
        <Typography.Title level={4} className="ml-20">
          {selectedChargerDetails[0]?.charger_title}&nbsp;
        </Typography.Title>
        <Row gutter={[16, 16]} style={{ margin: '15px 15px' }}>
          <Col xs={24} sm={24} md={12} lg={5} xl={4}>
            <Card
              size="small"
              style={{ minHeight: '173px', textAlign: 'center' }}
              cover={
                <img
                  src={selectedChargerDetails[0].charger_model.image_src}
                  className="imageCss"
                  alt="Charger Vendor Image"
                  onError={handleImageError}
                  style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                />
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={7} xl={4}>
            <Card size="small" title="Running Status" className="cardUnselected" loading={fetchingChargerData}>
              <div className="card-row">
                <img
                  src={chargerCurrentStatus === 'available' ? GreenCircle : OrangeCircle}
                  className={isStatusAvailableClassName}
                  alt="status"
                  width={15}
                  height={15}
                />
                <span className="status-name">Available</span>
              </div>
              <div className="card-row">
                <img
                  src={OrangeCircle}
                  className={isStatusChargingClassName}
                  alt="status"
                  width={15}
                  height={15}
                />
                <span className="status-name">Charging</span>
              </div>
              <div className="card-row">
                <img
                  src={chargerCurrentStatus === 'fault' ? RedCircle : OrangeCircle}
                  className={isStatusFaultedClassName}
                  alt="status"
                  width={15}
                  height={15}
                />
                <span className="status-name">Faulted</span>
              </div>
              {selectedChargerDetails[0].charger_model.model === 'HCS-60' && (
                <>
                  <Row gutter={[8, 8]}>
                    <Col span={3} className="circleStyle"></Col>
                    <Col span={21}>100% Power</Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col span={3} className="circleStyle"></Col>
                    <Col span={21}>50% Power</Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col span={3} className="circleStyle"></Col>
                    <Col span={21}>25% Power</Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col span={3} className="circleStyle"></Col>
                    <Col span={21}>Standby</Col>
                  </Row>
                </>
              )}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={16}>
            <StaticCard cardTitle="Power in Kw" style={{ height: 'auto' }} />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Card>
              <Tabs
                defaultActiveKey={sessionTabIndex}
                type="secondary"
                items={items}
                destroyInactiveTabPane={true}
                onChange={handleSessionChanges}
              ></Tabs>
            </Card>
          </Col>
        </Row>
        <Button
          onClick={hideChargerDetails}
          type="primary"
          className="backButton"
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default ChargerSection;
