import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { Card, Col, Row, Spin, Typography } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MAP_ZOOM_LEVEL } from "../../../utils/constants";
import "./GlMap.css";

function GlMaps({ cardHeight, enableControls = true, main = true }) {
  const avail_sites = useSelector((state) => state.sites.avail_sites);
  const selectedSite = useSelector((state) => state.sites.selectedSite);
  const siteLocation = avail_sites.find(
    (site) => site.name === selectedSite,
  )?.location;
  const [selectedSiteLocation, setSelectedSiteLocation] = useState("");
  const [selectedSiteLocationUpdated, setSelectedSiteLocationUpdated] = useState("");
  const [position, setPosition] = useState(null);
  const [loading, setLoading] = useState(false);

  const MAP_API_KEY = "AIzaSyDQls-mu7AavtL0jNC1-NDLvAA8OhyL8MQ";

  useEffect(() => {
    if (siteLocation?.latitude > 0) {
      const latitude = siteLocation.latitude;
      const longitude = siteLocation.longitude;
      setPosition({ lat: latitude, lng: longitude });
      setSelectedSiteLocation(siteLocation.formatted_address);
      setSelectedSiteLocationUpdated(siteLocation.last_update_at);
    }
  }, [siteLocation]);

  const responsiveCol = { xxl: 12, xl: 12, lg: 12, md: 24, sm: 24, xs: 24 };

  useEffect(() => {
    const longitude = _.get(position, "lng", "");
    const latitude = _.get(position, "lat", "");

    const isLongitudeEmpty = _.isEmpty(`${longitude}`);
    const isLatitudeEmpty = _.isEmpty(`${latitude}`);

    if (!isLongitudeEmpty && !isLatitudeEmpty) {
      try {
        const fetchUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${MAP_API_KEY}`;
        setLoading(true);
        fetch(fetchUrl).then(async (res) => {
          const data = await res.json();
          const formattedAddress = _.get(data, "results.0.formatted_address", "");
          if (formattedAddress) {
            setSelectedSiteLocation(formattedAddress);
          }
          setLoading(false);
        }).catch(err => {
          console.log("🚀 ~ fetch ~ err:", err)
        })
      } catch (error) {
        console.log("🚀 ~ useEffect ~ error:", error);
      }
    }
  }, [position]);

  if (!position) {
    return (
      <Card className="commonFlexDisplay">
        <Typography.Paragraph className="commonFlexDisplay additonal">
          No location set for this site.
        </Typography.Paragraph>
      </Card>
    );
  }

  return (
    <div
      className="gl-maps-container"
      style={cardHeight ? { height: cardHeight } : {}}
    >
      {position ? (
        <>
          <Row className="gl-maps-selected-site-location">
            {main && <Col {...responsiveCol}>{selectedSiteLocation}</Col>}
            {!main && <Col span={24}>{selectedSiteLocation}</Col>}
            {main && selectedSiteLocationUpdated && <Col {...responsiveCol} style={{ textAlign: "right", color: '#888' }}>Last updated: {selectedSiteLocationUpdated} UTC </Col>}
            {main && !selectedSiteLocationUpdated && <Col {...responsiveCol} style={{ textAlign: "right", color: '#888' }}>GPS OFF</Col>}
          </Row>
          {loading ? <Spin size="large">
            <Row justify="center">
              <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingCol">
                <Typography.Title level={2} type="secondary">Loading. Please wait!</Typography.Title>
              </Col>
            </Row>
          </Spin>
            : <APIProvider apiKey={MAP_API_KEY}>
              <Map
                style={{
                  border: "1px solid #d4d4d4",
                  height: cardHeight === undefined ? "90vh" : cardHeight,
                }}
                draggable={enableControls}
                defaultCenter={position}
                defaultZoom={MAP_ZOOM_LEVEL}
                options={{
                  mapTypeControl: enableControls,
                  zoomControl: enableControls,
                  streetViewControl: enableControls,
                  fullscreenControl: false,
                  mapTypeId: 'roadmap',
                  clickableIcons: false,
                  animation: false,
                  scrollwheel: enableControls,
                  disableDoubleClickZoom: enableControls,
                }}
                gestureHandling={"greedy"}
              >
                <Marker position={position} />
              </Map>
            </APIProvider>}
        </>
      ) : (
        <Typography.Paragraph
          style={{
            width: "100%",
            height: cardHeight === undefined ? "90vh" : cardHeight,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            fontSize: "20px",
          }}
        >
          Location unknown
        </Typography.Paragraph>
      )}
    </div>
  );
}

export default GlMaps;
