import React from 'react';
import { useDispatch } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { setDataVisible } from '../../redux/reducers/siteSlice';
import { INTRODUCTION } from '../../utils/constants';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
    const dispatch = useDispatch();

    return (
        <div
            role="alert"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
            }}
        >
            <ExclamationCircleOutlined
                style={{
                    fontSize: 32,
                    color: 'red', // Set icon color
                    paddingBottom: '20px',
                }}
            />
            <p>Something went wrong:</p>
            <pre>{error.message || 'Unknown'}</pre>
            <button onClick={resetErrorBoundary} className='m-10'>Try again</button>
            {/* <div>Or</div> */}
            {/* <a className='m-10' href='#'>Report the error here</a> */}
        </div>
    );
};

const AppErrorBoundary = ({ children }) => (
    <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
            // Reset the state of your app so the error doesn't happen again
        }}
    >
        {children}
    </ErrorBoundary>
);

export default AppErrorBoundary;
