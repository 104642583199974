import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Spin, Alert } from 'antd';
import axios from 'axios';
import Logout from '../../views/Logout/logout';
import { useSelector } from 'react-redux';

export default function Errorpage() {

    const [isLoading, setIsLoading] = useState('false');
    const authUrl = process.env.REACT_APP_AUTH_URL;

    const protocol = process.env.REACT_APP_PROTOCOL;
    const url = process.env.REACT_APP_API_URL;
    const baseUrl = protocol + url;

    const customerError = useSelector(state => state.customer.errorMessage);

    const clearCookie = () => {
        setIsLoading('true');
        localStorage.removeItem('user');
        axios
            .post(`${baseUrl}microapi/logout-cleanup/`, {}, {
                withCredentials: true,
            })
            .then((response) => {
                setIsLoading(false);
                // Handle the response as needed
                console.log("Response:", response.data);
                // Redirect the user to the OAuth2 authorization URL
                window.location.href = authUrl;


            })
            .catch((error) => {
                setIsLoading('false');
                console.log(error);
            });
    }

    // return (
    //     <Row justify="center">
    //         <Col xs={24} sm={22} md={20} lg={18} xl={16} style={{
    //             display: "flex",
    //             flexDirection: "column",
    //             alignItems: "center",
    //             justifyContent: "center",
    //             height: "100vh", // Set height to full viewport height
    //         }}>
    //             <Typography.Title level={2} type="secondary">Something went wrong.</Typography.Title>
    //             <Typography.Paragraph
    //                 style={{ color: 'blue', cursor: 'pointer' }}
    //                 onClick={clearCookie}
    //             >
    //                 <u>Click here to Login again</u>
    //             </Typography.Paragraph>
    //             {isLoading === 'true' && (
    //                 <Spin size="large" />
    //             )}
    //         </Col>
    //     </Row>

    // )
    return (
        <div className="centerContainer">
            <Alert
                message={customerError === "Network Error" ? customerError : "Contact Admin"}
                description={customerError === "Network Error" ? "Unable to reach the server" : "No permission has been added to your account. Please contact e-Boost admin."}
                type={customerError === "Network Error" ? "error" : "warning"}
                showIcon
                style={{ width: '100%', fontSize: '20px' }}
                action={<Logout />}
            />
        </div>
    );
}
