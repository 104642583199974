import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppContent from "./views/AppContent";
import Home from "./views/Home/home";
import Logout from "./views/Logout/logout";
import './App.css';
import AppErrorBoundary from "./views/ErrorBoundary/AppErrorBoundary";
import ErrorLoginPage from "./views/ErrorBoundary/ErrorLoginPage";

export default function App() {
  return (
    <BrowserRouter>
      <AppErrorBoundary>
        <Routes>
          <Route>
            <Route path="/" element={<AppContent />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/errorLogin" element={<ErrorLoginPage />} />
          </Route>
        </Routes>
      </AppErrorBoundary>
    </BrowserRouter>
  );
}

