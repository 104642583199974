import { fetchPictureList } from './apis';
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerList: null,
  cameraInfoList: [],
  cameraInfoDetails: [],
  isSuccessCustomer: false,
  errorMessage: '',
  hideIntroPage: false,
  userInfo: [],
  isLoadingUserInfo: false,
  isSuccessUserInfo: false,
}

const cameraSlice = createSlice({
  name: 'camera',
  initialState,
  reducers: {
    setSelectedCustomerName(state, action) {
      state.selectedCustomerName = action.payload;
    },
    // Define other reducers here
  },
  extraReducers: builder => { //used when to call async functions
    builder
      .addCase(fetchPictureList.pending, state => {
        state.isLoadingUserInfo = true;
      })
      .addCase(fetchPictureList.fulfilled, (state, action) => {
        state.isLoadingUserInfo = false;
        state.isSuccessUserInfo = true;
        state.cameraInfoList = action.payload;
      })
      .addCase(fetchPictureList.rejected, (state, action) => {
        state.isLoadingUserInfo = false;
        state.isSuccessUserInfo = false;
        state.errorMessage = action.payload;
      });
  }
});

export const {} = cameraSlice.actions;
export default cameraSlice.reducer;