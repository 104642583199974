import axios from "axios";
import { useNavigate } from "react-router-dom";
import React from "react";
import { LogoutOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { getTenant, initiateLogin } from "../../utils/common";

const tenant = getTenant(window.location.href);

// This component is for adding logout functionality
function Logout() {
  const navigate = useNavigate();

  const protocol = process.env.REACT_APP_PROTOCOL;
  const url = process.env.REACT_APP_API_URL;
  const baseUrl = protocol + url;

  const signOut = () => {
   
    axios
      .post(`${baseUrl}oauth/logout/`, { withCredentials: true })

      .then((data) => {
        console.log("<<< hit logout API! >>>");
        // navigate('/logout')
        localStorage.removeItem('user');
        initiateLogin()
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const reactDashboardUrl = process.env.REACT_APP_URL;
  // const signOut = () => {
  //   localStorage.removeItem('user');
  //   const logoutURL = `https://eboostdev.auth.ap-south-1.amazoncognito.com/logout?response_type=code&client_id=377djk1ui7n4u7gi14qon9dga3&redirect_uri=${reactDashboardUrl}&state=MlG9gMyakT-uyV0h1EFrYQ&scope=openid%20profile%20email`
  //   window.location.href = logoutURL;
  // };
  // const appVersion = process.env.REACT_APP_VERSION;

  return (
    <div onClick={signOut} style={{ paddingRight: "10px", cursor: "pointer" }}>
      {/* <div style={{ color: "#0a4776" }}>Version: 2.0</div> */}
      <div>
        <Tooltip title="Logout">
          <LogoutOutlined /> &nbsp; Logout
        </Tooltip>
      </div>
    </div>
  );
}

export default Logout;
