import { Col, Row, theme } from "antd";
import React from "react";
import "../home.css";
import { useSelector } from "react-redux";
import ChargerDetails from './chargerSection'
import ChargerLiveStatus from "./chargerLiveStatus";
import ChargerList from "./chargerList";
// This component is used to show the chargers attached to the site

function ChargerModel() {
  const chargerDetailsPageOpen = useSelector(state => state.sites.chargerDetailsPageOpen);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <div>
      {chargerDetailsPageOpen === 'false' && <Row justify="start" className="gapsixteen">
        <Col span={24}>
          <ChargerLiveStatus /> {/* Displaying the ChargerLiveStatus component */}
        </Col>
      </Row>}
      <Row justify="start" style={{ background: colorBgContainer, marginBottom: '20px' }} >
        <Col xs={24} >
          {chargerDetailsPageOpen === 'false' ? (
            <ChargerList />
          ) : (<ChargerDetails />)}
        </Col>
      </Row>
    </div>
  );
}

export default ChargerModel;