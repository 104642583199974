import axios from 'axios';

const protocol = process.env.REACT_APP_PROTOCOL;
const url = process.env.REACT_APP_API_URL;
const baseUrl = protocol + url;
const authUrl = process.env.REACT_APP_AUTH_URL;

export function getTenant(url) {

    let domain = url;
    if (url.includes("://")) {
        domain = url.split('://')[1];
    }
    const tenant = domain.split('.')[0];
    return tenant;
}
export function initiateLogin() {
    console.log('redirect work');

    // const reactDashboardUrl = process.env.REACT_APP_URL; 
    const authUrl = process.env.REACT_APP_AUTH_URL;

    // Redirect the user to the OAuth2 authorization URL
    window.location.href = authUrl;
};

export const clearCookie = () => {
    localStorage.removeItem('user');
    axios
        .post(`${baseUrl}microapi/logout-cleanup/`, {}, {
            withCredentials: true,
        })
        .then((response) => {
            // Handle the response as needed
            console.log("Response:", response.data);
            // Redirect the user to the OAuth2 authorization URL
            window.location.href = authUrl;


        })
        .catch((error) => {
            console.log(error);
        });
}


export const getBatteryStatus = (status) => {
    const statusCharging = ["Preparing", "Finishing", "Charging"];
    const statusAvailable = ["Available"];
    const statusFaulted = ["Faulted", "SuspendedEVSE", "SuspendedEV"];

    if (statusCharging.includes(status)) {
        return 'charging'
    }

    if (statusFaulted.includes(status)) {
        return 'fault'
    }

    return 'available'
}

export const timezoneAbbreviations = {
    '+00:00': 'GMT',
    '-01:00': 'CVT', // Cape Verde Time
    '-02:00': 'ART', // Argentina Time
    '-03:00': 'BRT', // Brasília Time
    '-03:30': 'NST', // Newfoundland Standard Time
    '-04:00': 'AST', // Atlantic Standard Time
    '-05:00': 'EST', // Eastern Standard Time
    '-06:00': 'CST', // Central Standard Time
    '-07:00': 'MST', // Mountain Standard Time
    '-08:00': 'PST', // Pacific Standard Time
    '-09:00': 'AKST', // Alaska Standard Time
    '-10:00': 'HST', // Hawaii-Aleutian Standard Time
    '-11:00': 'SST', // Samoa Standard Time
    '-12:00': 'BIT', // Baker Island Time
    '+01:00': 'CET', // Central European Time
    '+02:00': 'EET', // Eastern European Time
    '+03:00': 'MSK', // Moscow Time
    '+03:30': 'IRST', // Iran Standard Time
    '+04:00': 'GET', // Georgia Time
    '+04:30': 'AFT', // Afghanistan Time
    '+05:00': 'PKT', // Pakistan Standard Time
    '+05:30': 'IST', // Indian Standard Time
    '+05:45': 'NPT', // Nepal Time
    '+06:00': 'BST', // Bangladesh Standard Time
    '+06:30': 'CCT', // Cocos Islands Time
    '+07:00': 'WIB', // Western Indonesian Time
    '+08:00': 'CST', // China Standard Time
    '+09:00': 'JST', // Japan Standard Time
    '+09:30': 'ACST', // Australian Central Standard Time
    '+10:00': 'AEST', // Australian Eastern Standard Time
    '+11:00': 'SBT', // Solomon Islands Time
    '+12:00': 'FJT', // Fiji Time
    '+13:00': 'PHOT', // Phoenix Island Time
    '+14:00': 'LINT', // Line Islands Time
};