import { Col, Row, Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux';

export default function Solar() {
    const victronSite = useSelector(state => state.sites.victronSite);

    return (
        <React.Fragment>
            <Row justify="start" className='pb-10'>
                <Col span={24}>
                    <Typography.Title level={3} type="secondary">Solar</Typography.Title>
                    {/* <Row justify="start" className="rowGraph" style={{ height: '100vh' }}>
                        {victronSite ? (
                            <div style={{ width: "100%" }}>
                                <iframe width="100%" height="100%" style={{ border: "none", backgroundColor: "none" }}
                                    src={victronSite}></iframe>
                            </div>
                        ) : (
                            <Typography.Paragraph>Victron Not Linked!</Typography.Paragraph>
                        )}
                    </Row> */}
                    <Row justify="start" className="rowGraph" style={{ height: '80vh' }}>
                        {victronSite ? (
                            <div className='w-full h-full'>
                                <iframe
                                    title="victron-iframe"
                                    style={{ width: "100%", height: "100%", border: "none", backgroundColor: "none" }}
                                    src={victronSite}
                                ></iframe>
                            </div>
                        ) : (
                            <Typography.Paragraph>Victron Not Linked!</Typography.Paragraph>
                        )}
                    </Row>

                </Col>
            </Row>
        </React.Fragment>
    )
}
