import { Button, Card, Image, Radio } from "antd";
import React from "react";

import { CameraCardFunctions } from "./CameraCard";

const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

const onChange = (currentSlide) => {
  console.log(currentSlide);
};

export let CameraSearchToolBar = (props) => {
  return (
    <React.Fragment>
      <Card
        size="small"
        style={{ minHeight: "117px" }}
        className="cardUnselected"
        title="Filter"
      >
        &nbsp;&nbsp;
        <Radio.Group value="small">
          <Radio.Button value="large">Start of day</Radio.Button>
          <Radio.Button value="middle">Latest First</Radio.Button>
        </Radio.Group>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button onClick={() => CameraCardFunctions.refreshDataSet()}>
          Refresh
        </Button>
      </Card>
    </React.Fragment>
  );
};

const boxStyle = {
  width: "100%",
  height: 120,
  borderRadius: 6,
  border: "1px solid #40a9ff",
};

// export let ImagePager  = (props) => {
//      return (
//
//      );
// }

export let EbImageList = (props) => {
  return props.url.map((row) => {
    <React.Fragment>
      <Image height={100} width={150} src={row.url} />
      {row.camera}
      <br />
      {row.subtitle}
    </React.Fragment>;
  });
};

export let EbImage = (props) => {
  return (
    <React.Fragment>
      <Image height={100} width={150} src={props.url} />
      {props.camera}
      <br />
      {props.subtitle}
    </React.Fragment>
  );
};

export let EbImageRow = (props) => {
  return (
    <div style={{ marginLeft: 5}}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <div>
          <Image height={100} width={150} src={props.row.picturePath} />
        </div>
        <div>
          <div>{props.row.cameraName}</div>
          <div>{props.row.createdAt}</div>
        </div>
      </div>
    </div>
  );
};

// style={{border: '5px solid white', borderRadius: '2px', margin: "5px", marginBottom: '60px'}}

// preview={{
//     toolbarRender: (
//        _,
//         {
//             transform: {scale},
//             actions: {onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn},
//         },
//    ) => (
//         <Space size={12} className="toolbar-wrapper">
//            {/*<DownloadOutlined onClick={onDownload} />*/}
//             <SwapOutlined rotate={90} onClick={onFlipY}/>
//             <SwapOutlined onClick={onFlipX}/>
//            <RotateLeftOutlined onClick={onRotateLeft}/>
//            <RotateRightOutlined onClick={onRotateRight}/>
//            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut}/>
//            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn}/>
//        </Space>
//  ),
