import { Col, Row, Spin, Typography } from "antd";
import _ from 'lodash';
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllChargersCurrentStatus,
  fetchAllCustomerSites,
  fetchAllPermittedCustomers,
  fetchAllSessionDataForChargers,
  fetchAndFilterGraphData,
  fetchBPLiveData,
  fetchBPValues,
  fetchChargerList,
  fetchChargerListDummy,
  fetchGeneralDetails,
  fetchGeneratorList,
  fetchUserInfo,
  startDataFetchingInterval,
  startPollingForChargerData,
  startPollingForChargerDummyData
} from "../../redux/reducers/apis";
import { setCommunicationLink, setSelectedGeneratorId, setSelectedSiteName, setStarLink, setVictronSite } from '../../redux/reducers/siteSlice';
import Errorpage from "../NotFound/errorpage";
import SiteNotFound from '../NotFound/sitenotfound';
import SummaryDashboard from "../summary_dashboard/summary_dashboard";
import './home.css';

// This is the initial component loaded after login to the customer dashboard
function Home() {
  const dispatch = useDispatch();
  const customerList = useSelector(state => state.customer.customerList);
  const selectedCustomerName = useSelector(state => state.customer.selectedCustomerName);
  const dummyChargerData = useSelector((state) => state.sites.chargerDataDummy);
  const avail_sites = useSelector(state => state.sites.avail_sites);
  const selectedSite = useSelector(state => state.sites.selectedSite);
  const fromDate = useSelector(state => state.sites.fromDate);
  const toDate = useSelector(state => state.sites.toDate);
  const bpValue = useSelector(state => state.sites.bpValue);
  const isLoadingCustomer = useSelector(state => state.customer.isLoadingCustomer);
  const isSuccessCustomer = useSelector(state => state.customer.isSuccessCustomer);
  const isLoadingUserInfo = useSelector(state => state.customer.isLoadingCustomer);
  const chargersId = useSelector(state => state.charger.chargersId);
  const isLoadingCustomerSites = useSelector(state => state.sites.isLoadingCustomerSites);
  const selectedGeneratorId = useSelector(state => state.sites.selectedGeneratorId);
  const generatorList = useSelector(state => state.sites.generatorList);
  const isLoadingGeneratorList = useSelector(state => state.sites.isLoadingGeneratorList);

  useEffect(() => {
    if (dummyChargerData) {
    }
  }, [dummyChargerData]);

  useEffect(() => {
    dispatch(fetchUserInfo());
    dispatch(fetchAllPermittedCustomers());
  }, []);

  useEffect(() => {
    if (customerList) {
      dispatch(fetchAllCustomerSites(selectedCustomerName));
    }
  }, [customerList, selectedCustomerName]);

  useEffect(() => {
    if (avail_sites) {
      dispatch(setSelectedSiteName(avail_sites[0].name));
      dispatch(setVictronSite(avail_sites[0].victron_link));
      dispatch(setCommunicationLink(avail_sites[0].communication_link));
      dispatch(setStarLink(avail_sites[0].communication_link));
    }
  }, [avail_sites]);

  // Calling fetchAndFilterGraphData function while changing any one of these values fromDate, toDate, selectedSite, bpValue
  // useEffect(() => {
  //   if (avail_sites && !([undefined, null, ''].includes(selectedGeneratorId)) && selectedSite) {
  //     dispatch(fetchAndFilterGraphData({ selectedCustomerName, avail_sites, selectedSite, fromDate, toDate, bpValue, selectedGeneratorId }));
  //   }
  // }, [fromDate, toDate, selectedSite, bpValue, selectedGeneratorId]);

  useEffect(() => {
    if (avail_sites) {
      dispatch(fetchGeneratorList({ selectedCustomerName, avail_sites, selectedSite }));
      dispatch(fetchChargerList({ selectedCustomerName, avail_sites, selectedSite }));
      dispatch(fetchGeneralDetails({ selectedCustomerName, avail_sites, selectedSite }));
      dispatch(fetchAllSessionDataForChargers());
      // dispatch(fetchChargerListDummy());
      // dispatch(getChargerData());
    }
  }, [selectedSite]);

  useEffect(() => {
    if (avail_sites && selectedGeneratorId) {
      selectedGeneratorId && dispatch(fetchBPLiveData({ selectedCustomerName, avail_sites, selectedSite, selectedGeneratorId }));
      selectedGeneratorId && dispatch(fetchBPValues({ selectedCustomerName, avail_sites, selectedSite, selectedGeneratorId }));
    }
  }, [selectedSite, selectedGeneratorId]);

  useEffect(() => {
    if (generatorList && !isLoadingGeneratorList) {
      const isGeneratorIdSelected = generatorList.some(generator => generator.equipment_id === selectedGeneratorId);
      // setSelectedEquipmentId(generatorList[0].value);
      if (!isGeneratorIdSelected) {
        dispatch(setSelectedGeneratorId(generatorList && generatorList[0] && generatorList[0].equipment_id));
      }
    }
  }, [generatorList])

  useEffect(() => {
    if (avail_sites) {
      const payload = {
        selectedCustomerName,
        avail_sites,
        selectedSite,
        fromDate,
        toDate,
        bpValue,
        selectedGeneratorId
      };
      const clearPollingInterval = dispatch(startDataFetchingInterval(payload));
      return clearPollingInterval; // Returning the clearPollingInterval function
    }
  }, [dispatch, avail_sites, selectedCustomerName, selectedSite, fromDate, toDate, bpValue, selectedGeneratorId]);

  useEffect(() => {
    if (avail_sites) {
      const payload = {
        selectedCustomerName,
        avail_sites,
        selectedSite
      };
      const clearPollingInterval = dispatch(startPollingForChargerData(payload));
      return clearPollingInterval; // Returning the clearPollingInterval function
    }
  }, [dispatch, avail_sites, selectedCustomerName, selectedSite, fromDate, toDate, bpValue]);

  // useEffect(() => {
  //   const clearPollingInterval = dispatch(startPollingForChargerDummyData());
  //   return clearPollingInterval; // Returning the clearPollingInterval function
  // }, [])

  useEffect(() => {
    if (avail_sites) {
      if (!_.isEmpty(chargersId)) {
        dispatch(fetchAllChargersCurrentStatus({ chargersId, selectedCustomerName }))
      }
    }
  }, [chargersId])


  useEffect(() => {
    if (selectedSite && selectedCustomerName) {
      document.title = (customerList && customerList.filter(item => item.customer === selectedCustomerName)[0].name ?
        customerList.filter(item => item.customer === selectedCustomerName)[0].name : selectedCustomerName) + ' - ' + selectedSite + ' - ' + 'e-Boost' || 'e-BoostRealM';
    }
  }, [selectedSite, selectedCustomerName]);

  return (
    <div className="scrollY">
      {(customerList === null && isLoadingCustomer === false)
        ? <Errorpage /> //'waiting for admin permission page'
        : (customerList !== null && avail_sites === null && !isLoadingCustomerSites)
          ? <SiteNotFound customers={customerList}
            selectedCustomerName={selectedCustomerName}
            selectedSite={selectedSite} />
          : isLoadingCustomer === true || isLoadingUserInfo === true || isLoadingCustomerSites
            ? <Spin size="large">
              <Row justify="center">
                <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingCol" >
                  <Typography.Title level={2} type="secondary">Loading. Please wait!</Typography.Title>
                </Col>
              </Row>
            </Spin>
            : <SummaryDashboard />}
    </div>
  );
}

export default Home;
